.button {
	font-style: none;
	border: 0.1px solid #90909025;
	box-shadow: none;
	padding: 7px;
	margin-right: 12px;
	color: rgba(14, 13, 13, 0.904);
	background-color: #d0d3d4;
	border-radius: 3px;
	box-shadow: 0px 0px 5px #dadada;
	min-width: "150px"
}
.button:hover {
	color: black !important;
  background-color: #5a6268;
}

.heading-style {
	border-bottom: 1px solid #524f4f;
	width: 200px;
	color: #524f4f;
}
.heading-style2 {
	border-bottom: 1px solid #524f4f;
	width: 300px;
	color: #524f4f;
}
.behindComponent {
	width: 100%;
	height: 100%;
	background-size: cover;
	-webkit-filter: blur(4px);
	-moz-filter: blur(4px);
	-ms-filter: blur(4px);
	-o-filter: blur(4px);
	filter: blur(4px);
}
.dropzone_button {
	border: none;
	background-color: rgb(214, 212, 212);
	box-shadow: none;
	margin: 5px 30px;
	color: #524f4f;
}
.welcomeText {
	/* background-color:rgba(230, 231, 232, 0.81); */
	background-color: transparent;
	width: 150px;
	height: 25px;
	border: none;
	border-bottom: 1px solid #524f4f;
	margin: -16px;
}

.color-picker {
	/* width:700px;
  height:200px; */
	margin: 25px;
	z-index: 1;
	position: absolute;
}
.color-picker2 {
	/* width:700px;
  height:200px; */
	/* margin:25px; */
	z-index: 10;
	position: absolute;
}
.underlined-div {
	display: flex;
	width: 150px;
	border-bottom: 1px solid #524f4f;
}

.colorSelection {
	/* background-color:rgba(230, 231, 232, 0.81); */
	background-color: transparent;
	width: 130px;
	height: 25px;
	border: none;
}

.colorDisplay {
	width: 18px;
	height: 18px;
	border: 1px solid black;
	border-radius: 2px;
	/* margin-right: 10px; */
}

.dropdown1 {
	font-style: none;
	border: 0.1px solid #90909025;
	box-shadow: none;
	border-radius: 3px;
	padding: 7px;
	/* height: 40px; */
	color: rgba(14, 13, 13, 0.904);
	background-color: #d0d3d4;
	z-index: 1;
	margin: 0px;
}
.dropdown11 {
	font-style: none;
	border: 0.1px solid #90909025;
	box-shadow: none;
	border-radius: 0px;
	padding: 7px;
	/* height: 40px; */
	color: rgba(14, 13, 13, 0.904);
	background-color: #d0d3d4;
	min-width: 150px;
	margin-right: 12px;
	/* z-index:1;
  margin: 0px; */
}
.dropdown2 {
	font-style: none;
	border: 0.1px solid #90909025;
	box-shadow: 0 0 10px #dadada;
	border-radius: 0px;
	/* height: 40px; */
	padding: 7px;
	color: rgba(14, 13, 13, 0.904);
	background-color: #d0d3d4;
	z-index: 1;
	margin: 0px;
}
.show {
	padding: 0px;
	margin: 0px;
}
.showOptions {
	padding: 0px;
	margin: 0px;
}
.btn-secondary {
	/* padding:0px; */
	margin: 0px;
}
/*
.dropdown1:active{
  font-style:none;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 7px;
  color:rgba(14, 13, 13, 0.904);
  background-color:rgba(119, 117, 117, 0.2)

}

.dropdown1:hover{
  font-style:none;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 7px;
  color:rgba(14, 13, 13, 0.904);
  background-color:rgba(119, 117, 117, 0.2)

} 
 
.dropdown-content {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding:7px;
    background-color:rgba(0,0,0,0.2);
    box-shadow: none !important; 

   
  }

  
  .dropdown-content:hover { 
    -webkit-appearance: none;
    -moz-appearance: none;
      background-color:rgba(0,0,0,0.4)
    }
  
  :hover .dropdown-content {
    display: block;
  }
*/

.box {
	background-color: rgba(0, 0, 0, 0.2);
	margin: 5px;
	width: 220px;
	height: 180px;
	padding: 20px;
	font-size: large;
	text-align: center;
}

.img {
	float: right;
	width: 25px;
	height: 25px;
}

.date-range-picker {
	z-index: 1;
	position: absolute;
}
.button-grp {
	margin: 2px 2px;
	font-style: none;
	border: none;
	box-shadow: none;
	padding: 2px;
	color: rgba(14, 13, 13, 0.904);
	background-color: rgb(214, 212, 212);
}
.dropdown1 {
	margin: 5px;
	font-style: none;
	border: none;
	box-shadow: none;
	padding-bottom: 0px;
	/* padding: 12px; */
	color: rgba(14, 13, 13, 0.904);
	background-color: #d0d3d4;
}
.dummy_button {
	position: relative;
	display: inline-block;
}
.buttons {
	margin: 5px 15px;
	font-style: none;
	border: 0.1px solid #90909020;
	box-shadow: none;
	padding: 12px;
	color: rgba(14, 13, 13, 0.904);
	background-color: rgb(214, 212, 212);
	border-Radius: 3px;
	height: 43px;
}

.graphFig {
	margin: 3px;
	padding: 3px;
	color: rgba(14, 13, 13, 0.904);
	background-color: rgb(214, 212, 212);
}

.figures {
	color: rgba(14, 13, 13, 0.904);
	font-size: 60px;
	font-family: 'Crete Round';
	font-style: italic;
}
.type-dropdown {
	font-style: none;
	border: none;
	width: 150px;
	box-shadow: none;
	border-radius: 0px;
	/* padding: 7px; */
	color: rgba(14, 13, 13, 0.904);
	background-color: rgb(214, 212, 212);
	z-index: 1;
}
.select-dd {
	font-style: none;
	border: none;
	width: 160px;
	box-shadow: none;
	border-radius: 0px;
	height: 40px;
	margin-right: 2px;
	margin-bottom: 1px;
	padding: 7px;
	color: rgba(14, 13, 13, 0.904);
	background-color: rgb(214, 212, 212);
	z-index: 1;
}

.data-table {
	/* background-color:rgb(214, 212, 212); */
	font-size: 18px;
	text-align: center;
}

.rt-th {
	border: 5px solid rgb(147, 149, 153);
	box-shadow: none;
	/* width:35px; */
}
.rt-td {
	border: 5px solid rgb(240, 239, 240);
	background-color: #bcbec0;
	height: 50px;
	width: 70px;
}
.rt-tr .-odd {
	border: none;
}
.rt-tr .-even {
	border: none;
}
.tr-tbody .rt-tr-group .rt-tr .-odd .-even {
	border: none;
}
.ReactTable .rt-tbody {
	border: none;
}
.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: none;
}
.link_btn {
	/* position: absolute; */
	/* right: 6px; */
	float: right;
	/* top: 6px; */
	height: 32px;
	width: 32px;
	border: 0;
	border-radius: 50%;
	margin-top: 5px;
	margin-right: 5px;
	padding: 4px;
	background: #efefef;
}

.layout-container {
	border: 2px dashed #e6e7e8;
	padding: 60px 30px;
}

.first_box {
	border: 2px dashed #bcbec0;
	padding: 60px 30px;
}

.second_box {
	border: 2px dashed #bcbec0;
	padding: 5px 30px;
	margin-left: 20px;
	position: fixed;
	left: 58%;
}
.second_box1 {
	border: 2px dashed #bcbec0;
	margin-left: 20px;
}

.outer_button {
	background: #e6e7e8;
	border-radius: 50%;
	border: 0;
	/* position: absolute; */
	left: 50%;
	/* margin-left: -12px; */
	/* bottom: -10px; */
	padding: 0;
	width: 24px;
	height: 24px;
}
.button-plus {
	/* background: #e6e7e8; */
	/* border-radius: 50%; */
	/* border: 0; */
	left: 50%;
	/* width: 24px;
  height: 24px; */
}
.expo_button_selected {
	background: #939598;
	width: 100%;
	height: 54px;
	border: none;
	margin-top: 30px;
	margin-bottom: 3px;
	box-shadow: none;
}
.expo_button_notSelected {
	background: #e6e7e8;
	width: 100%;
	height: 54px;
	border: none;
	margin-top: 30px;
	margin-bottom: 3px;
	box-shadow: none;
}
.text_button {
	background: #e6e7e8;
	width: 85%;
	height: 54px;
	margin-bottom: 5px;
	margin-right: 3px;
	border: none;
	padding: 10px;
	box-shadow: none;
	color: #939598;
	text-align: left;
}
.button_divs {
	background: hsl(210, 4%, 91%);
	width: 15%;
	height: 54px;
	padding-top: 10px;
	padding-right: 15px;
}
.radio_unclicked {
	float: right;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	border: 1px solid #939598;
	color: #e6e7e8;
	margin-right: 5px;
	margin-top: 5px;
}
.radio_clicked {
	float: right;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	background: #939598;
	margin-right: 5px;
	margin-top: 5px;
}
.add-text {
	margin: 5px;
	border-radius: 50%;
	margin-top: 40px;
	box-shadow: none;
	border: none;
}

.plus_light {
	background: #939598;
	border: none;
	box-shadow: none;
	margin-top: 12px;
}

.input_field {
	width: 100%;
	/* height: 30px; */
	margin-top: 2px;
	width: 40%;
	background-color: #e6e7e8;
	text-align: left;

	margin-bottom: 1px;
	/* padding:2px 5px; */
	display: flex;
}
.input_field2 {
	width: 80%;
	height: 30px;
	margin-top: 10px;
	background-color: #e6e7e8;
}

.delete_icon {
	/* float:right; */
	border: none;
	box-shadow: none;
	border-radius: 50%;
}
.delete_icon2 {
	border: none;
	box-shadow: none;
	border-radius: 50%;
}
.delete_icon3 {
	border: none;
	box-shadow: none;
	border-radius: 50%;
}

/* dd 1 */
.multi-level,
.item ul,
.navi input[type='checkbox'] {
	display: none;
	width: 100%;
}
#menu:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#menu0:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#menu1:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#menu2:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#menu3:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#menu4:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#show:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#show0:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#show1:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#show2:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#show3:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
#show4:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}

#showOptions:checked ~ .multi-level,
.item input:checked ~ ul {
	display: block;
}
/*Arrow*/

.dropdown_arrow {
	width: 12px;
	height: 12px;
	vertical-align: middle;
	float: left;
	z-index: 0;
	margin: 13px 1em 0 1em;
}
.item input + .arrow {
	transform: rotate(-90deg);
	transition: 0.1s;
}
.item input:checked + .arrow {
	transform: rotate(0deg);
	transition: 0.1s;
}

/*Styles*/

.checkboxLable:hover {
	cursor: pointer;
}
.checkboxLable {
	width: 100%;
	display: block;
	z-index: 3;
	position: relative;
}
.editorClassName {
	background: white;
	margin-bottom: 1px;
	padding-left: 10px;
}

.navi {
	margin: 0px 10px;
	width: 90%;
	background-color: #e6e7e8;
	overflow-x: hidden;
	border-bottom: 1px solid #cfd8dc;
}

#navi-icon {
	text-align: left;
	line-height: 54px;
	padding-left: 1em;
	color: #939598;
	background-color: #e6e7e8;
}
#navi-icon2 {
	font-size: 15px;
	text-align: left;
	padding-left: 1em;
}
.navi ul,
.navi li,
.checkboxLable {
	line-height: 35px;
	margin: 0;
	/* padding: 0 2em; */
	/* padding-left: 1em; */
	/* padding-right: 3px; */
	list-style: none;
	text-decoration: none;

	color: #939598;
	/* font-weight: 100; */
	width: 100%;
}
.item ul {
	padding: 0 0.25em;
}
.navi li {
	/* line-height: 40px; */
	margin: 0;
	/* padding: 0 1em; */
	/* padding: 0 0 0 1em; */
	list-style: none;
	text-decoration: none;
	color: #939598;
	font-weight: 100;
}
/* dd2 */

/*Func*/

.multi-level1,
.item1 ul,
.navi1 input[type='checkbox'] {
	display: none;
}
#menu1:checked ~ .multi-level1,
.item1 input:checked ~ ul {
	display: block;
}

/*Arrow*/

.arrow {
	width: 12px;
	height: 12px;
	vertical-align: middle;
	float: left;
	z-index: 0;
	margin: 13px 1em 0 2em;
}
.item input + .arrow {
	transform: rotate(-90deg);
	transition: 0.1s;
}
.item input:checked + .arrow {
	transform: rotate(0deg);
	transition: 0.1s;
}

/*Styles*/

label:hover {
	cursor: pointer;
}
label {
	width: 100%;
	display: block;
	z-index: 3;
	/* position: relative; */
}
.navi1 {
	width: 100%;
	background-color: white;
	overflow-x: hidden;
	border-bottom: 1px solid #cfd8dc;
}

#nav-icon1 {
	font-size: 28px;
	line-height: 50px;
	padding-left: 1em;
	color: white;
	background-color: #f44336;
}
/* 
.navi1 ul, .navi1 li, label {
  line-height: 50px;
  margin: 0;
  padding: 0 2em;
  list-style: none;
  text-decoration: none;
  color: #90A4AE;
  font-weight: 100;
  width: 100%;
} */
.item1 ul {
	padding: 0 0.25em;
}
.navi1 li a {
	line-height: 50px;
	margin: 0;
	padding: 0 4em;
	list-style: none;
	text-decoration: none;
	color: #90a4ae;
	font-weight: 100;
}

.quest {
	width: 50%;
	background-color: #e6e7e8;
	text-align: left;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 2px 5px;
}
.quest1 {
	width: 60%;
	background-color: #e6e7e8;
	text-align: left;
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 8px 5px;
	display: flex;
}
.qtn_optns {
	display: flex;
	margin: 1px 3px;
}
.qtn_optns2 {
	display: flex;
	margin: 0px 3px;
}
.optn {
	width: 50%;
	/* height:50px; */
	background-color: #e6e7e8;
	margin-top: 1px;
	margin-bottom: 1px;
	margin-left: 2px;
	margin-right: 0px;
	text-align: left;
	padding: 2px 4px;
}
.optn-image {
	/* height:50px; */
	background-color: #e6e7e8;
	margin-top: 1px;
	margin-bottom: 1px;
	margin-left: 2px;
	margin-right: 0px;
	text-align: left;
	padding: 2px 4px;
}

.inp {
	width: 102%;
	border: none;
	/* outline: none; */
	/* height:50px; */
	background-color: #e6e7e8;
	/* margin:2px 2px; */
}

.inp-image {
	width: 200px;
	border: none;
	/* outline: none; */
	/* height:50px; */
	background-color: #e6e7e8;
	/* margin:2px 2px; */
}
.inp1 {
	width: 60%;
	border: none;
	/* outline: none; */
	/* height:50px; */
	background-color: #e6e7e8;
	margin-top: 1px;
	margin-left: 5px;
	margin-right: 1px;
	margin-bottom: 1px;
	padding: 8px;
}

.plan_preview_nav {
	width: 100%;
	background-color: #e6e7e8;
	height: 50px;
	display: flex;
	border-bottom: 2px dashed #cfd8dc;
}
.preview_nav {
	width: 100%;
}
.home_img {
	width: 25px;
	height: 25px;
	margin: 10px;
}

.plan_preview_info {
	text-align: center;
	padding-top: 12px;
}

.event_plan_details {
	/* width: 50%;
    margin-left: 150px; */
	border: 2px dashed #cfd8dc;
	padding: 40px;
	margin: 15px 0px;
}

.back_to_plan {
	margin-top: 20px;
	margin-left: 20px;
}

.download {
	border: none;
	float: right;
	margin-top: 10px;
	margin-right: 5px;
}
.eg {
	width: 100%;
	background: #e6e7e8;
	height: 50px;
	border: none;
	margin: 1px 2px;
}
.content_pic_upload {
	display: flex;
	margin: 2px;
}
.close_icon {
	float: right;
	margin-right: 1px;
	margin-top: 1px;
	width: 10px;
	height: 10px;
}

.upload_icon {
	float: left;
	margin-left: 2px;
	margin-top: 2px;
	width: 18px;
	height: 18px;
}

.secondHalf {
	width: 100%;
	display: flex;
	/* margin-top:1px; */
	margin-left: 2px;
}

.secondHalfPreview {
	width: 100%;
	/* margin-top:1px; */
	margin-left: 2px;
}
._icon {
	float: right;
	width: 20px;
	height: 25px;
	margin: 1px;
}

.dropdown1.dropdown-menu {
	max-height: 200px !important;
	overflow: auto !important;
}
@media (max-width: 270px) {
	.image {
		/* height: 200px; */
		object-position: 0% 0%;
		overflow: auto;
		object-fit: fill;
		width: 100%;
	}
	.step-circle {
		width: 18px;
		height: 18px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
		margin-bottom: 20px;
		/* justify-content: center; */
		/* margin:40px -30px */
	}
	.layout-box-vertical-preview {
		/* width: 100%; */
		height: 100%;
		display: block;
		position: relative;
		overflow: auto;
		width: 200px;
		margin-left: -10px;
	}

	.table-width {
		width: 300px;
	}
	.priceWrapperPricebar {
		display: none;
		border-left: 0.1px solid #90909020;
	}
	.pull-bar-toggle {
		float: right;
		margin-right: 10px;
	}
	.themesWrapper {
		background: #f0eff0;
		display: none;
		border-right: 1px solid #70707044;
	}
	.outline-button {
		min-width: 50px;
	}
	.inputWrapper {
		display: block;
		margin-right: 50px;
	}
	.inputpadding {
		padding-left: 0px;
	}
	.content {
		flex: 0px;
		overflow: auto;
	}
	.input-width {
		width: 100px;
	}
	.contentWrapper {
		padding: 32px;
	}
	.button-alignment {
		display: grid;
	}
	.speichern {
		margin-top: 20px;
	}
}
@media (min-width: 271px) and (max-width: 360px) {
	.image {
		/* height: 200px; */
		object-position: 0% 0%;
		overflow: auto;
		object-fit: fill;
		width: 100%;
	}
	.step-circle {
		width: 18px;
		height: 18px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.layout-box-vertical-preview {
		/* width: 100%; */
		height: 100%;
		display: block;
		position: relative;
		/* vertical-align: top; */
		overflow: auto;
		width: 200px;
		/* margin-left: 20px; */
		margin-left: 0;
	}
	.table-width {
		width: 300px;
		width: auto;
	}
	.priceWrapperPricebar {
		display: none;
		border-left: 0.1px solid #90909020;
	}
	.pull-bar-toggle {
		float: right;
		margin-right: 10px;
	}
	.themesWrapper {
		background: #f0eff0;
		display: none;
		border-right: 1px solid #70707044;
	}
	.outline-button {
		min-width: 50px;
	}
	.inputWrapper {
		display: block;
		margin-right: 50px;
	}
	.inputpadding {
		/* padding-left: 0px; */
		display: flex;
	}
	.content {
		flex: 0px;
		overflow: auto;
	}
	.input-width {
		width: 100px;
	}
	.contentWrapper {
		padding: 32px;
	}

	.button-alignment {
		display: flex !important;
	}
	.upload-width {
		width: '300px';
	}
}

@media (min-width: 361px) and (max-width: 480px) {
	.image {
		display: block;
		width: 100%;
		/* height: 250px; */
		/* maxHeight: "360px", */
		object-fit: fill;
		object-position: 0% 0%;
		overflow: auto;
	}
	.step-circle {
		width: 18px;
		height: 18px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		/* justify-content: center; */
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.layout-box-vertical-preview {
		/* width: 100%; */
		height: 100%;
		display: block;
		position: relative;
		/* vertical-align: top; */
		overflow: auto;
		width: 200px;
		margin-left: 0px;
	}
	.table-width {
		width: 200px;
		width: auto;
	}
	.priceWrapperPricebar {
		display: none;
		border-left: 0.1px solid #90909020;
	}
	.pull-bar-toggle {
		float: right;
		margin-right: 10px;
	}
	.themesWrapper {
		background: #f0eff0;
		display: none;
		border-right: 1px solid #70707044;
	}
	.outline-button {
		min-width: 50px;
	}
	.inputWrapper {
		display: block;
		margin-right: 50px;
	}

	.input-width {
		width: 100px;
	}
	.inputpadding {
		margin-left: 10px;
		display: flex;
	}
	.content {
		flex: 0px;
		overflow: auto;
	}
	.contentWrapper {
		padding: 32px;
	}
	.button-alignment {
		display: flex !important;
	}
	.upload-width {
		width: '300px';
	}
}
@media (min-width: 481px) and (max-width: 510px) {
	.image {
		display: block;
		width: 100%;
		/* height: 250px; */
		/* maxHeight: "360px", */
		object-fit: fill;
		object-position: 0% 0%;
		overflow: auto;
	}
	.image-height {
		margin-top: -20px;
	}
	.step-circle {
		width: 38px;
		height: 38px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		/* justify-content: center; */
		/* margin:0px -79px */
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.layout-box-vertical-preview {
		width: 140px;
		height: 100%;
		display: inline-block;
		position: relative;
		vertical-align: top;
		overflow: auto;

		/* margin-left: 50px; */
	}
	.priceWrapperPricebar {
		display: none;
		border-left: 0.1px solid #90909020;
	}
	.pull-bar-toggle {
		float: right;
		margin-right: 10px;
	}
	.themesWrapper {
		background: #f0eff0;
		display: none;
		border-right: 1px solid #70707044;
	}
	.content {
		flex: 0px;
		overflow: hidden;
	}

	.outline-button {
		min-width: 100px;
	}
	.inputWrapper {
		display: inline-block;
		margin-right: 50px;
		/* width: 20%; */
		padding-left: 30px;
	}
	.displayInput {
		display: flex;
	}

	.input-width {
		width: 150px;
	}
	.inputpadding {
		margin-left: -70px;
	}
	.contentWrapper {
		padding: 32px 80px;
	}
	.button-alignment {
		display: flex !important;
	}
	.upload-width {
		width: '300px';
	}
}
@media (min-width: 511px) and (max-width: 670px) {
	/* for question preview */
	.image-height {
		margin-top: -20px;
	}
	.image {
		display: block;
		width: 100%;
		/* height: 250px; */
		/* maxHeight: "360px", */
		object-fit: fill;
		object-position: 0% 0%;
		overflow: auto;
	}
	.step-circle {
		width: 38px;
		height: 38px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 30px -79px;
		/* margin-top: 20px; */
	}
	.layout-box-vertical-preview {
		width: 170px;
		height: 100%;
		display: inline-block;
		position: relative;
		vertical-align: top;
		/* margin-left: -40px; */
		/* padding: 0px 20px; */
		overflow: auto;

		/* margin-left: -70px; */
	}
	/* .table-width{
          width:200px
        } */
	.priceWrapperPricebar {
		display: none;
		border-left: 0.1px solid #90909020;
	}
	.pull-bar-toggle {
		float: right;
		margin-right: 10px;
	}
	.themesWrapper {
		background: #f0eff0;
		display: none;
		border-right: 1px solid #70707044;
	}
	.content {
		flex: 0px;
		overflow: hidden;
	}

	.outline-button {
		min-width: 100px;
	}
	.inputWrapper {
		display: inline-block;
		margin-right: 50px;
		/* width: 20%; */
		padding-left: 30px;
	}
	.displayInput {
		display: flex;
	}

	.input-width {
		width: 200px;
	}
	.inputpadding {
		margin-left: -50px;
	}
	.contentWrapper {
		padding: 32px 70px;
	}
	.button-alignment {
		display: flex !important;
	}
	.upload-width {
		width: '300px';
	}
}
@media (min-width: 670px) and (max-width: 872px) {
	/* for question preview */
	.image-height {
		margin-top: -20px;
	}
	.image {
		display: block;
		width: 100%;
		/* height: 250px; */
		/* maxHeight: "360px", */
		object-fit: fill;
		object-position: 0% 0%;
		overflow: auto;
	}
	.step-circle {
		width: 38px;
		height: 38px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.layout-box-vertical-preview {
		width: 170px;
		height: 100%;
		display: inline-block;
		position: relative;
		vertical-align: top;
		margin-left: 40px;
		overflow: auto;
	}
	/* .table-width{
              width:200px
            } */
	.priceWrapperPricebar {
		display: none;
		border-left: 0.1px solid #90909020;
	}
	.pull-bar-toggle {
		float: right;
		margin-right: 10px;
	}
	.themesWrapper {
		background: #f0eff0;
		display: none;
		border-right: 1px solid #70707044;
	}
	.content {
		flex: 0px;
		overflow: hidden;
	}

	.outline-button {
		min-width: 100px;
	}
	.inputWrapper {
		display: inline-block;
		margin-right: 50px;
		/* width: 20%; */
		padding-left: 30px;
	}
	.displayInput {
		display: flex;
	}

	.input-width {
		width: 200px;
	}
	.inputpadding {
		margin-left: -50px;
	}
	.contentWrapper {
		padding: 32px 70px;
	}
	.button-alignment {
		display: flex !important;
	}
	.upload-width {
		width: '300px';
	}
}
@media (min-width: 873px) and (max-width: 1024px) {
	/* for question preview */
	.image-height {
		margin-top: -20px;
	}
	.image {
		display: block;
		width: 100%;
		/* height: 250px; */
		/* maxHeight: "360px", */
		object-fit: fill;
		object-position: 0% 0%;
		overflow: auto;
	}
	.step-circle {
		width: 38px;
		height: 38px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.layout-box-vertical-preview {
		width: 170px;
		height: 100%;
		display: inline-block;
		position: relative;
		vertical-align: top;
		margin-left: 150px;
		overflow: auto;
	}
	/* .table-width{
        width:200px
      } */
	.priceWrapperPricebar {
		display: none;
		border-left: 0.1px solid #90909020;
	}
	.pull-bar-toggle {
		float: right;
		margin-right: 10px;
	}
	.themesWrapper {
		background: #f0eff0;
		display: none;
		border-right: 1px solid #70707044;
	}
	.content {
		flex: 0px;
		overflow: hidden;
	}

	.outline-button {
		min-width: 100px;
	}
	.inputWrapper {
		display: inline-block;
		margin-right: 50px;
		/* width: 20%; */
		padding-left: 30px;
	}
	.displayInput {
		display: flex;
	}

	.input-width {
		width: 200px;
	}
	.inputpadding {
		margin-left: -50px;
	}
	.contentWrapper {
		padding: 32px 70px;
	}
	.button-alignment {
		display: flex !important;
	}
	.upload-width {
		width: '300px';
	}
}

@media (min-width: 1025px) and (max-width: 1056px) {
	.image-height {
		margin-top: -20px;
	}
	.image {
		display: block;
		width: 100%;
		/* height: 250px; */
		/* maxHeight: "360px", */
		object-fit: fill;
		object-position: 0% 0%;
		overflow: auto;
	}
	.step-circle {
		width: 38px;
		height: 38px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.layout-box-vertical-preview {
		width: 200px;
		height: 100%;
		display: inline-block;
		position: relative;
		vertical-align: top;
		/* margin-left: 60px; */
		padding: 0 20px;
		overflow: auto;
	}

	.priceWrapperPricebar {
		display: block;
		border-left: 0.1px solid #90909020;
	}
	.pullPriceBar {
		display: none;
	}

	.content {
		flex: 0 0 66.666667%;
		max-width: 100%;
	}
	.rightbarpreview {
		display: none;
	}
	.themesWrapper {
		background: #f0eff0;
		border-right: 1px solid #70707044;
	}
	.outline-button {
		min-width: 143px;
	}
	.inputWrapper {
		display: inline-block;
		margin-right: 50px;
		/* width: 40%; */
		padding-left: 10px;
	}
	.displayInput {
		display: flex;
	}
	.input-width {
		width: 200px;
	}
	.inputpadding {
		margin-left: 60px;
	}
	.contentWrapper {
		/* padding: 32px 120px; */
	}
	.button-alignment {
		display: flex !important;
	}
	.upload-width {
		width: '300px';
	}
}
@media (min-width: 1057px) {
	.image-height {
		margin-top: -20px;
	}
	.image {
		display: block;
		width: 100%;
		/* height: 350px;
          */
		object-fit: fill;
		object-position: 0% 0%;
		overflow: auto;
	}
	.step-circle {
		width: 48px;
		height: 48px;
		display: block;
		margin: 0 auto 4px;
		text-align: center;
		background: #00000000;
		border-radius: 50%;
		border-width: 1px;
		border-color: #297825;
		outline: none;
	}
	.stepsContainer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.layout-box-vertical-preview {
		width: 200px;
		height: 100%;
		display: inline-block;
		position: relative;
		vertical-align: top;
		overflow: auto;
		/* margin-left: 9px; */
	}
	.priceWrapperPricebar {
		display: block;
		border-left: 0.1px solid #90909020;
	}
	.pullPriceBar {
		display: none;
	}

	.content {
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.rightbarpreview {
		display: none;
	}
	.themesWrapper {
		background: #f0eff0;
		border-right: 1px solid #70707044;
	}
	.outline-button {
		min-width: 143px;
	}
	.inputWrapper {
		display: inline-block;
		margin-right: 50px;
		/* width: 40%; */
		padding-left: 10px;
	}
	.displayInput {
		display: flex;
	}
	.input-width {
		width: 200px;
	}
	.contentWrapper {
		padding: 32px 80px;
	}
	.button-alignment {
		display: flex !important;
	}
}

/* For Login Landing */
@media (min-width: 1307px) {
	.display {
		display: none;
	}
}
@media (max-width: 246px) {
	.word {
		display: block;
		width: 150px;
		height: 50px;
	}
}
@media (max-width: 246px) {
	.word {
		/* font-size: 14px;
word-break: break-word; */
		display: block;
		width: 100px;
		height: 50px;
		/* margin-top: 20px; */
	}
}
@media (max-width: 380px) {
	.word {
		/* font-size: 14px;
word-break: break-word; */
		width: 200px;
		display: block;
		/* display: inline-block; */
		height: 50px;
	}
}
@media (min-width: 381px) {
	.word {
		/* font-size: 14px;
word-break: break-word; */
		width: calc(33.3333333% - 20px);
		display: inline-block;
		/* height:50px */
	}
}
.btn-secondary:hover {
	color: black !important;
}
.btn-secondary.dropdown-toggle {
	color: black !important;
	border-radius: 3px;
	box-shadow: 0px 0px 5px #dadada;
}
.rdw-link-modal {
	position: absolute;
	top: 35px;
	left: 5px;
	display: flex;
	flex-direction: column;
	width: 235px;
	height: 250px;
	border: 1px solid #f1f1f1;
	padding: 15px;
	border-radius: 2px;
	z-index: 100;
	background: white;
	box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-link-modal-btn {
	width: 90px;
}

/* scrollbar width */
.scrollbar::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
    background: #C9D6FF;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 10px;
}

/* scrollbar Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: #585858;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #cccccc, #cccccc);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #cccccc, #cccccc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 10px;
}

/* scrollbar Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #707070;
}