/* Global Styles */
@import url("https://fonts.googleapis.com/css?family=Crete+Round:400,400i|Roboto:300,300i,400,400i,700,700i|Caveat:400,700&display=swap");
body {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  font-family: "Roboto";
  min-height: 100vh;
}
@media (min-width: 1200px) {
  .container {
    max-width: 940px;
  }

}
.separator {
  display: block;
  width: 100%;
  height: 2px;
  background: #297825;
  margin-bottom: 20px;
}
.outlineButton {
  color: #297825;
  font-family: "Crete Round";
  font-size: 13px;
  border: 1px solid #297825;
  padding: 9px 15px;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
}
.solidButton {
  background: #297825;
  font-family: "Crete Round";
  border: 1px solid #297825;
  color: #fff;
  font-size: 13px;
  padding: 9px 15px;
  text-align: "center";
  display: inline-block;
  margin-right: "15px";
}
.radioCircle{
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
  background: transparent;
  border-radius: 12px;
  border: 1px solid #bbbbbb;
  outline: none;
  cursor: pointer;
}
.radioLabel{
  margin: 0 0 0 20px;
  padding: 0;
  color: #939598;
}
.checkboxValues{
  padding: 0;
  margin: 0 0 0 20px;
}
.green-border-btn {
  color: #297825;
  font-family: "Crete Round";
  font-size: 13px;
  border: 1px solid #297825;
  min-width: 143px;
  padding: 9px 15px;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
  height:40px
}
.green-border-btn:hover {
  text-decoration: none;
  color: #297825;
}
.green-filled-btn {
  color: #297825;
  background: #297825;
  font-family: "Crete Round";
  border: 1px solid #297825;
  color: #fff;
  font-size: 13px;
  min-width: 143px;
  padding: 9px 15px;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
  height: 40px;
}
.green-filled-btn:hover {
  text-decoration: none;
  color: #fff;
}
.popup-overlay {
  /* position: absolute; */
  background-color: #00000060;
  position: fixed;
  width: 100vw;
  /* height: 100vh; */
  height: 100vh;
  left: 0;
  top: 0;
  /* margin:auto 0; */
  display: table;
  text-align: center;
  /* overflow-x: scroll !important; */
}
.popup-overlay .popup {
  vertical-align: middle;
  display: table-cell;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input:focus,
button:focus {
  outline: none;
}
/* Login page */
.login-section-wrapper {
  border-radius: 3px ;
  background: rgba(230, 231, 232, 0.81);
  width: 620px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 42px;
}
.login-section-wrapper .login-section-left {
  margin-top: 21px;
}
.login-section-wrapper .login-section-left p {
  font-family: "Crete Round";
  font-style: italic;
  font-size: 36px;
  color: #297825;
  text-align: center;
}
.login-section-wrapper .login-section-left .company-logo {
  width: 100%;
  height: auto;
  max-width: 180px;
  margin-left:43px;
}
.login-section-wrapper .login-section-right {
  margin-top: 45px;
  text-align: right;
}
.login-section-wrapper .login-section-right .form-control {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 0;
  line-height: 42px;
  height: auto;
  font-weight: 300;
  font-size: 14px;
  color: #6d6e71;
  border: 0;
}
.login-section-wrapper .login-section-right .error-msg {
  color: #ff0000;
  font-size: 13px;
  margin-bottom: 6px;
}
.login-section-wrapper .login-section-right .login-button {
  background: #297825;
  color: #fff;
  border: 0;
  padding: 8px 21px;
  border-radius: 0;
  font-family: "Crete Round";
  font-size: 10px;
}
.login-section-wrapper .login-section-right .login-button:hover {
  background: #1a4918;
}
.login-section-wrapper .login-section-right a {
  font-family: "Crete Round";
  font-style: italic;
  font-size: 10px;
  color: #297825;
  display: block;
}
@media (max-width: 350px) {
  .login-section-wrapper {
    max-width: 280px;
    width: 100%;
    min-height: 400px;
  }
  .login-section-wrapper .login-section-left {
    margin-left: 0;
  }
  .login-section-wrapper .login-section-left p {
    font-size: 25px;
    text-align: center;
  }
}
@media  (min-width:351px) and (max-width: 480px) {
  .login-section-wrapper {
    max-width: 290px;
    width: 100%;
    min-height: 400px;
  }
  .login-section-wrapper .login-section-left {
    margin-left: 0;
  }
  .login-section-wrapper .login-section-left p {
    font-size: 25px;
    text-align: center;
  }
}
@media  (min-width:481px) and (max-width: 768px) {
  .login-section-wrapper {
    max-width: 385px;
    width: 100%;
    min-height: 400px;
  }
  .login-section-wrapper .login-section-left {
    margin-left: 0;
  }
  .login-section-wrapper .login-section-left p {
    font-size: 25px;
    text-align: center;
  }
}
/* Forgot Password Page */
.forget-password-section {
  border-radius: 3px;
  background: rgb(230, 231, 232, 0.81);
  max-width: 342px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 42px;
}
.forget-password-section h1 {
  font-family: "Crete Round";
  font-size: 36px;
  color: #297825;
  line-height: 36px;
  text-align: center;
  margin-bottom: 12px;
}
.forget-password-section .form-control {
  border-radius: 0;
  border: 0;
  line-height: 42px;
  height: auto;
  font-weight: 300;
  font-size: 14px;
}
.forget-password-section p {
  margin-top: 12px;
  font-family: "Crete Round";
  font-size: 10px;
  color: #297825;
  text-align: center;
}
.forget-password-section .reset-password-button {
  background: #297825;
  color: #fff;
  font-family: "Crete Round";
  font-size: 10px;
  border: 0;
  margin: 15px auto;
  padding: 8px 21px;
  display: block;
}
.forget-password-section .reset-password-button:hover {
  background: #1a4918;
}
.forget-password-section .reset-password-text {
  font-family: "Crete Round";
  font-size: 10px;
  color: #297825;
}
/* Left bar */

.left-bar.collapsed {
  left: -250px;
}
.left-bar .logout-btn {
  position: absolute;
  left: 15px;
  top: 15px;
  background: transparent;
  border: 0;
  transition: 0.3s;
}
.left-bar.collapsed .logout-btn {
  left: 275px;
}
.left-bar .home-btn {
  position: absolute;
  left: 60px;
  top: 15px;
  background: transparent;
  border: 0;
  transition: 0.3s;
  border: none;
}
.left-bar.collapsed .home-btn {
  left: 310px;
  border: none;
}
@media (max-width:1307px) {
        
  .left-bar .left-bar-toggle {
    position: absolute;
    left: 90px;
    top: 15px;
    background: transparent;
    border: 0;
    transition: 0.3s;
    outline: none;
  }
 
}
@media (min-width:1307px) {
.left-bar .left-bar-toggle {
  position: absolute;
  left: 90px;
  top: 15px;
  background: transparent;
  border: 0;
  transition: 0.3s;
  outline: none;
}
}
.left-bar .left-bar-toggle.collapsed {
  transform: rotate(180deg);
  left: 340px;
}


.left-bar .secondary-company-logo {
  margin-top:0px;
  margin-bottom: auto;
  /* margin-top: 150px; */
  margin: 10px 55px;
  /* margin-bottom: 60px; */
  width: 200px;
  display: block;
  /* margin-left: 20px; */
}

.left-bar .heart-container {
  margin-bottom: auto;
  width: 200px;
  height: 165px;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.left-bar .heart-container .heart-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.left-bar .heart-container .couple-image {
  margin-bottom: auto;
  width: 100%;
  height: auto;
}
.left-bar .event-details {
  text-align: center;
  font-family: "Crete Round";
  font-size: 20px;
  color: #000;
  margin-bottom: auto;
}
.left-bar .von-text {
  margin-top: 45px;
  font-style: italic;
  text-align: center;
  font-weight: 300;
  font-size: 17px;
  color: #000;
}
.left-bar .couple-name {
  display: block;
  margin: 0 auto;
  padding: 0 15px;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  color: #000;
}
.left-bar .bottom-section {
  position: absolute;
  width: 250px;
  bottom: 0;
  left: 0;
}

.left-bar .bottom-section .planner-image img {
  width: 100%;
  height: auto;
}
@media(max-height:530px){
  .date-alignment{
    justify-content: center;
      display: none;
      font-size: 11px;
      margin-top: -20px;
    }
    .left-bar .heart-container {
      margin-bottom: auto;
      width: 180px;
      height: 100px;
      display: block;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
    }
  .left-bar .bottom-section .planner-details {
    background: rgba(41, 120, 37, 0.7);
    /* padding:15px; */
    padding: 0px;
   /* margin-bottom: -80px; */
  }

  .left-bar {
    display: block;
    width: 250px;
    border-right: 1px solid #297825;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.3s;
    background: #fff;
    z-index: 50;
    /* overflow: auto; */
  }
  .left-bar .bottom-section .planner-image {
    image-rendering: crisp-edges;
   
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: none;
    margin: 0 auto;
    margin-bottom: -30px;
  }
  .left-bar .main-company-logo img {
    margin-top: 0px;
    margin-bottom: auto;
    width: 100%;
    height: 50px;
  }
  .left-bar .main-company-logo {
    margin-top: 0px;
    margin-bottom: auto;
    width: 200px;
    display: block;
    margin-top: 45px;
    margin-left: 25px;
  }
  .left-bar .secondary-company-logo img {
    margin-bottom: 20px;
    margin-top:0px;
    width: 100%;
    height: 50px;
  }
  .left-bar .bottom-section .planner-details p {
    font-size: 11px;
    font-weight: 300;
    color: #fff;
    /* font-style: italic; */
  }
  .left-bar .bottom-section .planner-details h6 {
    font-family: "Crete Round";
    font-size: 20px;
    padding-top: 20px;
    /* font-style: italic; */
    color: #fff;
  }

  .navImage, .navArrow{
    width: 20px !important;
    height: 20px !important;
  }
  .navItem, .arrowWrapper{
    padding: 6px !important;
  }
  
  .navItem, .arrowWrapper:hover{
    background-color: #191919;
  }

  .pointerIcon{
    padding: 6px !important;
  }
  .topIcon{
    height: 50vh !important;
  }
}
/* @media (min-height:475px)and(max-height:530px){
  .date-alignment{
    justify-content: center;
      display: none;
      font-size: 11px;
      margin-top: -20px;
    }
    .left-bar .heart-container {
      margin-bottom: auto;
      width: 180px;
      height: 100px;
      display: block;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
    }
  .left-bar .bottom-section .planner-details {
    background: rgba(41, 120, 37, 0.7);
 
    padding: 25px 15px 0px;
  
  }
  .left-bar {
    display: block;
    width: 250px;
    border-right: 1px solid #297825;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.3s;
    background: #fff;
    z-index: 90;
   
  }
  .date-alignment{
    justify-content: center;
      display: flex;
      font-size: 11px;
      margin-top: -20px;
    }
  .left-bar .bottom-section .planner-image {
    image-rendering: crisp-edges;
   
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    margin-bottom: -30px;
  }
  .left-bar .main-company-logo img {
    margin-top: 0px;
    margin-bottom: auto;
    width: 100%;
    height: 80px;
  }
  .left-bar .main-company-logo {
    margin-top: 0px;
    margin-bottom: auto;
    width: 200px;
    display: block;
    margin-top: 45px;
    margin-left: 25px;
  }
  .left-bar .secondary-company-logo img {
    margin-bottom: 20px;
    margin-top:0px;
    width: 100%;
    height: 80px;
  }
  .left-bar .bottom-section .planner-details p {
    font-size: 11px;
    font-weight: 300;
    color: #fff;
  
  }
  .left-bar .bottom-section .planner-details h6 {
    font-family: "Crete Round";
    font-size: 21px;
   
    color: #fff;
  }
} */
@media(min-height:531px) and (max-height:620px){
  .left-bar .bottom-section .planner-details {
    background: rgba(41, 120, 37, 0.7);
    /* padding:15px; */
    padding: 30px 15px 20px;
  }
  
  .left-bar {
    display: block;
    width: 250px;
    border-right: 1px solid #297825;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.3s;
    background: #fff;
    z-index: 50;
    /* overflow: auto; */
  }
  .left-bar .bottom-section .planner-image {
    image-rendering: crisp-edges;
   
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    margin-bottom: -30px;
  }
  .left-bar .main-company-logo img {
    margin-top: 0px;
    margin-bottom: auto;
    width: 100%;
    height: 80px;
  }
  .left-bar .main-company-logo {
    margin-top: 0px;
    margin-bottom: auto;
    width: 200px;
    display: block;
    margin-top: 50px;
    margin-left: 25px;
  }
  .left-bar .secondary-company-logo img {
    margin-bottom: 20px;
    margin-top:0px;
    width: 100%;
    height: 80px;
  }
  .left-bar .bottom-section .planner-details p {
    font-size: 10px;
    font-weight: 300;
    color: #fff;
    /* font-style: italic; */
  }
  .left-bar .bottom-section .planner-details h6 {
    font-family: "Crete Round";
    font-size: 21px;
    /* font-style: italic; */
    color: #fff;
  }
}
@media(min-height:621px){
  .left-bar .bottom-section .planner-details {
    background: rgba(41, 120, 37, 0.7);
    /* padding:15px; */
    padding: 32px 15px 15px;
  }
  .left-bar {
    display: block;
    width: 250px;
    border-right: 1px solid #297825;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.3s;
    background: #fff;
    z-index: 50;
  }
  .left-bar .bottom-section .planner-image {
    image-rendering: crisp-edges;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    margin: 0 auto -30px
  }
  .left-bar .main-company-logo img {
    margin-top: 0px;
    margin-bottom: auto;
    width: 100%;
    height: auto;
  }
  .left-bar .main-company-logo {
    margin-top: 0px;
    margin-bottom: auto;
    width: 200px;
    display: block;
    margin-top: 50px;
    margin-left: 25px;
  }
  .left-bar .secondary-company-logo img {
    margin-bottom: 20px;
    margin-top:0px;
    width: 100%;
    height: auto;
  }
  .left-bar .bottom-section .planner-details p {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  /* font-style: italic; */
}
.left-bar .bottom-section .planner-details h6 {
  font-family: "Crete Round";
  font-size: 21px;
  /* font-style: italic; */
  color: #fff;
}

}



.left-bar .bottom-section .planner-details .email-container {
  color: #fff;
  font-size: 11px;
  display: inline-block;
}
.left-bar .bottom-section .planner-details .email-container img {
  margin-right: 6px;
}
.left-bar .bottom-section .planner-details .phone-container {
  color: #fff;
  font-size: 11px;
  /* float: right; */
  display: inline-block;
}
.left-bar .bottom-section .planner-details .phone-container img {
  margin-right: 6px;
}
.config-left-bar .main-logo {
  width: 196px;
  height: auto;
  display: block;
  /* margin: 40px auto 70px; */
  margin: 15px auto 25px;
}
.config-left-bar .template-container {
  width: 100%;
  padding: 0 15px;
  /* border-right: 1px solid #d1d3d4; */
}
.config-left-bar .template-container .template-item {
  display: block;
  font-family: "Crete Round";
  color: #a2a4a5;
  font-size: 17px;
  cursor: pointer;
}
.config-left-bar .template-container .add-template-input {
  background: #d1d3d4;
  border-radius: 3px;
  padding: 6px 15px;
  color: #939598;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
  border: 0;
  width: 100%;
}
.config-left-bar .template-container .add-template-btn {
  padding: 0;
  border-radius: 50%;
  border: 0;
  text-align: center;
  width: 24px;
  background: #d1d3d4;
}
/* Right bar */
.right-bar {
  position: fixed;
  background: #e6e7e8;
  right: 0;
  top: 0;
  display: block;
  width: 280px;
  height: 100vh;
  transition: 0.3s;
  z-index: 30;
  padding: 10px 23px;
}
.right-bar.collapsed {
  right: -280px;
}
@media (max-width:1307px) {
  .right-bar .right-bar-collapse {
    position: absolute;
    left: 140px;
    top: 15px;
    background: transparent;
    border: 0;
    outline: none;
    transition: 0.3s;
    transform: rotate(180deg);
  }
  .right-bar .right-bar-collapse.collapsed {
    transform: rotate(0deg);
    left: -110px;
  }
  }
@media (min-width:1307px) {
.right-bar .right-bar-collapse {
  position: absolute;
  left: -54px;
  top: 15px;
  background: transparent;
  border: 0;
  outline: none;
  transition: 0.3s;
  transform: rotate(180deg);
}
.right-bar .right-bar-collapse.collapsed {
  transform: rotate(0deg);
  left: -96px;
}
}

.right-bar .pricing-section {
  padding: 60px 30px 0;
  text-align: right;
}
.right-bar .pricing-section h6,
.config-right-bar .pricing-section h6 {
  font-family: "Crete Round";
  font-style: italic;
  font-size: 23px;
  color: #000;
  margin-bottom: 0;
}
.right-bar .pricing-section h1,
.config-right-bar .pricing-section h1 {
  font-size: 48px;
  font-weight: 700;
  color: #000;
}
.config-right-bar .pricing-section h1 {
  margin-bottom: 30px;
}
.right-bar .pricing-section .below-price {
  font-weight: 300;
  font-style: italic;
  color: #000;
  font-size: 17px;
  margin-bottom: 6px;
}
.right-bar .pricing-section .seperator,
.config-right-bar .pricing-section .seperator {
  display: block;
  width: 100%;
  height: 2px;
  background: #297825;
}
.right-bar .pricing-section .price-item {
  margin-top: 24px;
  font-style: italic;
  font-weight: 300;
  color: #000;
  font-size: 17px;
}
.right-bar .pricing-section .price-number {
  font-weight: 600;
  color: #000;
  font-size: 17px;
}
.right-bar .bottom-section,
.config-right-bar .bottom-section {
  position: absolute;
  bottom: 0;
  padding: 30px;
  text-align: right;
  color: #000;
}
.right-bar .bottom-section h4,
.config-right-bar .bottom-section h4 {
  font-size: 24px;
  font-family: "Crete Round";
  /* font-style: italic; */
}
.right-bar .bottom-section h6,
.config-right-bar .bottom-section h6 {
  font-weight: 300;
  /*font-style: italic;*/
  font-size: 17px;
  margin-bottom: 45px;
  /*color: #939598;*/
}
.right-bar .bottom-section h5,
.config-right-bar .bottom-section h5 {
  font-weight: 600;
  font-size: 17px;
}
.right-bar .bottom-section .address-main,
.config-right-bar .bottom-section .address-main {
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 45px;
}
.right-bar .bottom-section .address-tel,
.config-right-bar .bottom-section .address-tel {
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 0;
}
.right-bar .bottom-section .address-mail,
.config-right-bar .bottom-section .address-mail {
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 0;
  word-break: break-all;
}
.config-right-bar {
  background: #e6e7e8;
  height: 100vh;
  text-align: right;
  padding-top: 60px;
  position: fixed;
  right: 0;
}
.config-right-bar .bottom-section {
  width: calc(100% - 30px);
  padding-left: 0;
  padding-right: 0;
}
/*.config-right-bar .bottom-section h4 {
  color: #939598; 
}*/


/* Pull Bar */
.pullLeft-bar-toggle {
  position: fixed;
  top: 15px;
  left: 75px;
  background: #939598;
  /* width: 30px; */
  height: 30px;
  border-radius: 50%;
  border: 0;
  z-index: 90;
}
.pullLeft-bar-toggle span {
  width: 15px;
  height: 2px;
  border-radius: 2px;
  display: block;
  background: #fff;
  margin: 3px 0;
  transition: 0.3s;
}
.pullLeft-bar-toggle:not(.collapsed) span:first-child {
  transform: rotate(45deg) translate(4px, 3px);
}
.pullLeft-bar-toggle:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
.pullLeft-bar-toggle:not(.collapsed) span:last-child {
  transform: rotate(-45deg) translate(4px, -3px);
}
.pullLeft-bar {
  position: fixed;
  top: 0;
  left: -360px;
  background: #e6e7e8;
  display: block;
  height: 100vh;
  width: 100vw;
  z-index: 60;
  overflow-y: scroll;
  transition: 0.3s;
}

.pullLeft-bar.collapsed {
  left: -100vw;
}
.pullLeft-bar .container {
  margin-right: 90px;
}

/* Pull Bar */
.pull-bar-toggle {
  position: fixed;
  top: 15px;
  right: 15px;
  background: #939598;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  border: 0.1px solid #90909030;
  z-index: 90;
  box-shadow: 0 0 5px #dadada;
}
.pull-bar-toggle span {
  width: 15px;
  height: 2px;
  border-radius: 2px;
  display: block;
  background: #fff;
  margin: 3px 0;
  transition: 0.3s;
}
.pull-bar-toggle:not(.collapsed) span:first-child {
  transform: rotate(45deg) translate(4px, 3px);
}
.pull-bar-toggle:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
.pull-bar-toggle:not(.collapsed) span:last-child {
  transform: rotate(-45deg) translate(4px, -3px);
}
.pull-bar {
  position: fixed;
  top: 0;
  right: -360px;
  background: #e6e7e8;
  display: block;
  height: 100vh;
  width: 100vw;
  z-index: 60;
  overflow-y: scroll;
  transition: 0.3s;
  border: 0.1px solid #90909030;
  box-shadow: 0 0 15px #90909020;
  border-radius: 25px 0 0 25px;
}
.pull-bar.collapsed {
  right: -100vw;
}
pull-bar.collapsed {
  right: -100vw;
}
.pull-bar .container {
  margin-left: 90px;
}
.pull-bar .total-price {
  margin-top: 60px;
  text-align: right;
}
.pull-bar .total-price h5 {
  font-family: "Crete Round";
  font-style: italic;
  font-size: 24px;
}
.pull-bar .total-price h1 {
  font-weight: 700;
  font-size: 48px;
}
.pull-bar .total-price .seperator {
  display: block;
  background: #297825;
  width: 100%;
  height: 2px;
  margin-bottom: 30px;
}
.pull-bar .list-item h4 {
  font-family: "Crete Round";
  font-size: 24px;
  font-style: italic;
  margin-bottom: 24px;
}
.pull-bar .list-item .item-name {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 300;
}
.pull-bar .list-item .item-price {
  font-weight: 600;
}
.pull-bar .row.second {
  margin-top: 60px;
}
@media (max-width: 1690px) {
  .pull-bar {
    right: 0px;
  }
}
@media (max-width: 1070px) {
  .pull-bar .container {
    max-width: 720px;
  }
}
@media (max-width: 830px) {
  .pull-bar .container {
    max-width: calc(100% - 90px);
  }
}
/* Login landing */
.login-landing {
  padding-top: 120px;
}
.login-landing h5 {
  font-family: "Crete Round";
  font-size: 23px;
  margin-bottom: 30px;
  color: #000;
}
/*.login-landing p {
  
  font-weight: 300;
  font-style: italic;
 
} */
.login-landing .signature {
  font-family: "Caveat";
  font-style: 23px;
  color: #808285;
  margin-bottom: 60px;
}
.login-landing .event-dates .individual {
  /* display: inline-block; */
  border: 2px dashed #bcbec0;
  padding: 60px 0;
  
  /* width: calc(33.3333333% - 20px); */
  margin-right: 30px;
  text-align: center;
  /* color: #000; */
  color: #808285;;
  font-family: "Crete Round";
  font-style: 24px;
  font-style: italic;
}
.login-landing .event-dates .individual:nth-child(3n) {
  margin-right: 0;
  margin-bottom: 30px;
}
/* Login Event Landing */
.login-event-landing {
  padding-top: 120px;
}
@media (max-width:331px){
  .login-event-landing .steps-indicator {
    width: 200px;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 60px;
  }
  .login-landing .event-dates .individual {
    /* display: inline-block; */
    border: 2px dashed #bcbec0;
    padding: 60px 0;
    /* width:200px; */
    /* width: calc(33.3333333% - 20px); */
    margin-right: 30px;
    text-align: center;
    /* color: #000; */
    color: #808285;;
    font-family: "Crete Round";
    font-style: 24px;
    font-style: italic;
  }
  .alignment{
margin-left: 60px;
  }
  .text-alignment{
    margin-left: 20px;
    margin-right: 150px;
  }
.disp{
  display: grid;
  margin-left: 50px;
}
.login-event-landing .links-container {
  text-align: center;
}
.login-event-landing .steps-indicator .circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #297825;
  display: block;
  margin: 30px auto 18px;
}
}
@media (min-width:332px){
  .noch{
    width: 90px;
    display:inline-block
  }
  .login-event-landing .steps-indicator {
    width: 250px;
    text-align: center;
    font-size: 10px;
    font-weight: 300;
    margin-bottom: 60px;
  }
  .alignment{
margin-left: 60px;
  }
  .text-alignment{
    margin-left: 20px;
    margin-right: 0px;
  }
.disp{
  display: flex;
  /* margin-left: -50px;
width: 700px; */
  /* margin-left: 50px; */
}
.login-event-landing .links-container {
  text-align: center;
}
.login-event-landing .steps-indicator .circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #297825;
  display: block;
  margin: 30px auto 18px;
}
}
@media (min-width:333px) and (max-width:456px) {
  .noch{
    width: 90px;
    display:inline-block
  }
  .login-event-landing .steps-indicator {
    width: 320px;
    text-align: center;
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 60px;
  }
  .alignment{
margin-left: 20px;
  }
  .text-alignment{
    margin-left: 20px;
    margin-right: 0px;
  }
.disp{
  display: flex;
  margin-left: 0px;
}
.login-event-landing .links-container {
  text-align: center;
  display: flex;
  margin-left: -20px;

}
.login-event-landing .steps-indicator .circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #297825;
  display: block;
  margin: 30px auto 18px;
}
}
@media (min-width:456px) and (max-width:670px) {
  .noch{
    width: 100px;
    display:inline-block
  }
  .login-event-landing .steps-indicator {
    width: 400px;
    text-align: center;
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 60px;
  }
  .alignment{
margin-left: 20px;
  }
  .text-alignment{
    margin-left: 20px;
    margin-right: 150px;
  }
.disp{
  display: flex;
  margin-left: 0px;
}
.login-event-landing .links-container {
  text-align: center;
  display: flex;
}
.login-event-landing .steps-indicator .circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #297825;
  display: block;
  margin: 26px auto 18px;
}
}
@media (min-width:670px) and (max-width:996px) {
  .noch{
    width: 150px;
    display:inline-block
  }
  .login-event-landing .steps-indicator {
    width: 500px;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 60px;
  }
  .alignment{
margin-left: 100px;
  }
  .text-alignment{
    margin-left: 45px;
    margin-right: 150px;
  }
  .disp{
    display: flex;
  }
  .login-event-landing .links-container {
    text-align: center;
    display: flex;
  }
  .login-event-landing .steps-indicator .circle {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #297825;
    display: block;
    margin: 26px auto 18px;
  }
}
@media (min-width:996px) {
.login-event-landing .steps-indicator {
  width: 85%;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 60px;
  margin-left: 20%;
  display: flex;
}
.noch{
  width: 150px;
  display:inline-block
}
.alignment{
  margin-left: 200px;
    }
    .text-alignment{
      margin-left: 150px;
      margin-right: 150px;
    }
    .disp{
      display: flex;
      width: 800px;
      margin-left: 0px;
    }
    .login-event-landing .links-container {
      text-align: center;
      display: flex;
    }
    .login-event-landing .steps-indicator .circle {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: #297825;
      display: block;
      margin: 26px auto 18px;
    }
}

.login-event-landing .steps-indicator .empty-circle {
  /* width: 50px;
  height: 58px;
  border-radius: 50%;
  border: 1px solid #297825;
  display: block;
  margin: 6px auto; */
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #297825;
  display: block;
  margin: 26px auto 18px;
}
/* .login-event-landing .steps-indicator .circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #297825;
  display: block;
  margin: 30px auto 18px;
} */
.login-event-landing .steps-indicator .line {
  display: block;
  height: 1px;
  background: #297825;
  position: absolute;
  top: 83px;
}
.login-event-landing .steps-indicator .first-step {
  width: 20%;
  display: inline-block;
  position: relative;
}

.login-event-landing .steps-indicator .first-step .line {
  width: 62px;
  right: 0px;
}
.login-event-landing .steps-indicator .second-step {
  width: 20%;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.login-event-landing .steps-indicator .second-step .circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #297825;
  display: block;
  margin: 26px auto 18px;
}
.login-event-landing .steps-indicator .second-step .line {
  width: calc(45% - 13px);
  left: 0;
}
.login-event-landing .steps-indicator .second-step .line.second {
  left: auto;
  right: 0;
}
.login-event-landing .steps-indicator .second-step p {
  font-family: "Crete Round";
  /* font-style: italic; */
}
.login-event-landing .steps-indicator .third-step {
  width: 20%;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.login-event-landing .steps-indicator .third-step .line {
  width: calc(50% - 24px);
}
.login-event-landing .steps-indicator .third-step .line.second {
  right: 0;
}
.login-event-landing .steps-indicator .fourth-step {
  width: 20%;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.login-event-landing .steps-indicator .fourth-step .line {
  width: calc(50% - 24px);
}
.login-event-landing .steps-indicator .fourth-step .line.second {
  right: 0;
}


.login-event-landing .steps-indicator .fifth-step {
  width: 30%;
  position: relative;
  vertical-align: top;
  display: inline-block;
}
.login-event-landing .steps-indicator .fifth-step .line {
  width: calc(50% - 24px);
}
.login-event-landing .steps-indicator .sixth-step .line.second {
  right: 0;
}
.login-event-landing .steps-indicator .sixth-step {
  width: 20%;
  position: relative;
  vertical-align: top;
  display: inline-block;
}
.login-event-landing .steps-indicator .sixth-step .line {
  width: calc(50% - 24px);
}
/* .login-event-landing .steps-indicator .sixth-step .line.second {
  right: 0;
} */
/* .login-event-landing .steps-indicator .sixth-step {
  width: 20%;
  display: inline-block;
  position: relative;
}

.login-event-landing .steps-indicator .sixth-step .line {
  width: 62px;
  right: 0px;
}
.login-event-landing .steps-indicator .sixth-step .line {
  width: calc(50% - 24px);
} */
.login-event-landing > h5 {
  font-style: 23px;
  font-family: "Crete Round";
  color: #000;
  margin-bottom: 30px;
}
.login-event-landing > p {
  font-weight: 300;
  font-style: italic;
}
.login-event-landing .signature {
  font-size: 23px;
  color: #808285;
  font-family: "Caveat";
}

@media (max-width: 992px) {
  .login-event-landing .steps-indicator .second-step {
    width: 34%;
  }
}
@media (max-width: 768px) {
  .login-event-landing .steps-indicator .second-step {
    width: 17%;
  }
}
/* Configurator Landing */
.configurator-landing .row.blur {
  filter: blur(10px);
}
.configurator-landing .dropzone {
  background: #d1d3d4;
  width: 100%;
  cursor: pointer;
  text-align: center;
  overflow: auto;
  min-height: 175px;
  margin-bottom: 20px;
  margin-top: 0px;
  background-size: cover;
  background-position: center center;
}
.configurator-landing .dropzone img {
  margin-top: 30px;
  margin-bottom: 15px;
}
.configurator-landing .dropzone p {
  font-size: 20px;
  font-family: "Crete Round";
  font-style: italic;
  color: #707070;
}
.configurator-landing .dropzone button {
  display: block;
  background: #939598;
  font-size: 20px;
  color: #e6e7e8;
  margin: 6px auto 30px;
  padding: 6px 12px;
  border: 0;
  border-radius: 3px;
}
.configurator-landing .dropzone button:hover {
  background: #505050;
}

.configurator-landing .steps-indicator {
  padding: 0 30px;
}
.configurator-landing .steps-indicator .step-item {
  text-align: center;
  display: inline-block;
  width: 15%;
  position: relative;
}
.configurator-landing .steps-indicator .step-item .circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  background: #d1d3d4;
  margin: 0 auto;
  margin-bottom: 6px;
  border: 0;
}
.configurator-landing .steps-indicator .step-item .editCircle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: block;
  background: #d1d3d4;
  margin-top: -28px;
  margin-left: 27px;
  border: 0;
}
.configurator-landing .steps-indicator .step-item .circle.green {
  /* background: #297825; */
  border: 2px solid #297825
}
.configurator-landing .steps-indicator .step-item .step-name {
  font-weight: bold;
  color: #939598;
  font-size: 12px;
  background: #d1d3d4;
  border-radius: 3px;
  display: inline-block;
  padding: 0 3px;
  border: 0;
  text-align: center;
}
.configurator-landing .steps-indicator .step-item .line {
  display: block;
  height: 1px;
  width: calc(50% - 39px);
  position: absolute;
  right: 0;
  top: 24px;
  background: #d1d3d4;
}
.configurator-landing .steps-indicator .step-item .line.second {
  right: auto;
  left: 0;
}
.configurator-landing .steps-indicator .step-item:first-child .line.second {
  display: none;
}
.configurator-landing .steps-indicator .step-add {
  display: inline-block;
  vertical-align: top;
  width: 15%;
  position: relative;
}
.configurator-landing .steps-indicator .step-add .circle {
  width: 48px;
  height: 48px;
  display: block;
  margin: 0 auto;
  text-align: center;
  background: #d1d3d4;
  border-radius: 50%;
  border: 0;
}
.configurator-landing .steps-indicator .step-add .line {
  display: block;
  height: 1px;
  width: calc(50% - 39px);
  position: absolute;
  left: 0;
  top: 24px;
  background: #d1d3d4;
}
.configurator-landing .single-template {
  margin-top: 30px;
  border-radius: 3px;
}
.configurator-landing .single-template .pick-template {
  width: calc(100% - 60px);
  height: 466px;
  border: 0;
  margin: 0 30px;
  background: #d1d3d4;
  border-radius: 5px;
  border: 0.1px solid #70707025;
  box-shadow: 0 0 25px #70707025;
}

.configurator-landing .single-template .question-image {
  display: inline-block;
  width: 300px;
  height: auto;
  margin-right: 60px;
  vertical-align: top;
}
.configurator-landing .single-template .question-radio {
  display: inline-block;
  width: calc(100% - 360px);
}
.configurator-landing .single-template .question-radio h6 {
  /* font-family: "Crete Round";
  font-style: italic; */
  font-size: 24px;
  color: #939598;
  margin-bottom: 30px;
}
.configurator-landing .single-template .question-radio .radio-option-container {
  margin-bottom: 30px;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-option {
  display: block;
  font-size: 17px;
  color: #939598;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-option
  input {
  width: 1px;
  height: 1px;
  opacity: 0;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-option
  input
  + span {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #297825;
  vertical-align: -8px;
  margin-right: 24px;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-option
  input:checked
  + span {
  background: #297825;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-sub-option {
  margin-left: 52px;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-sub-option
  .guests-input {
  border: 0;
  border-bottom: 1px solid #707070;
  background: transparent;
  width: 60px;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-sub-option
  .guests-input::placeholder {
  color: #d1d3d4;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-sub-option
  .guests-text {
  /* font-family: "Crete Round";
  font-style: italic; */
  font-size: 15px;
  color: #939598;
  display: block;
}
.configurator-landing
  .single-template
  .question-radio
  .radio-option-container
  .radio-sub-option
  .error-msg {
  color: #939598;
  margin-left: 15px;
}
.configurator-landing .single-template .question-text {
  /* font-family: "Crete Round";
  font-style: italic; */
  font-size: 20px;
  margin-top: 60px;
}
.configurator-landing .links-container {
  text-align: center;
  margin-top: 45px;
  margin-bottom: 90px;
  
}
/*Template selection popup */
.template-selection-popup {
  text-align: center;
}
.template-selection-popup .popup-container {
  margin: 1%;
  background: #d1d3d4;
  width: 96%;
  display: inline-block;
  position: relative;
  height: 95vh;
  overflow: auto;
  border-radius: 8px;
  border: 0.1px solid #90909030;
  box-shadow: 0 0 50px #00000050;
}
.template-selection-popup .popup-container .outline {
  border: 1px dashed #bcbec0;
  padding: 30px;
  border-radius: 8px;
  margin: 70px 20px 20px 20px;
}
.template-selection-popup .popup-container .save-btn {
  position: absolute;
  background: #e6e7e8;
  padding: 0;
  width: 90px;
  height: 36px;
  border: 0;
  right: 60px;
  top: 15px;
  color: #939598;
}
.template-selection-popup .popup-container .close-btn {
  position: absolute;
  background: #e6e7e8;
  padding: 0;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  border: 0;
  right: 15px;
  top: 15px;
}
.template-selection-popup .popup-container .closeTemplate,
.template-selection-popup .popup-container .saveTemplate{
  position: fixed;
	border-width: 0;
	border-radius: 4px;
	padding: 0.5rem 1rem 0.5rem 1rem;
  box-shadow: 0 0 5px #70707025;
}

.template-selection-popup .popup-container .closeTemplate:hover,
.template-selection-popup .popup-container .saveTemplate:hover{
  background-color: #505050;
  color: #fcfcfc;
}

@media (min-height: 700px){
  .template-selection-popup .popup-container .closeTemplate {
   top: 3.5vh;
    right: 50px;
  }
   .template-selection-popup .popup-container .saveTemplate {
  right: 115px;
    top: 3.5vh;
}
}

@media (min-height: 900px){
  .template-selection-popup .popup-container .closeTemplate {
   top: 3.5vh;
    right: 50px;
  }

  .template-selection-popup .popup-container .saveTemplate {
   top: 3.5vh;
    right: 115px;
  }
}

@media (min-width:1400px){
  .template-selection-popup .popup-container .saveTemplate {
  top: 3.5vh;
    right: 115px;
  }
  .template-selection-popup .popup-container .closeTemplate {
  
     top: 3.5vh;
    right: 50px;
  }
}

@media (min-width:1300px){
  .template-selection-popup .popup-container .saveTemplate {
    top: 3.5vh;
    right: 115px;
  }
  .template-selection-popup .popup-container .closeTemplate {
   top: 3.5vh;
    right: 50px;
  }
}

@media (min-width:1600px) {
  .template-selection-popup .popup-container .closeTemplate {
    top: 3.5vh;
    right: 65px;
  }
   .template-selection-popup .popup-container .saveTemplate {
    top: 3.5vh;
    right: 130px;
  }
}


/* Template selection component */
.template-selection-popup .popup-container .outline .select-text {
  font-size: 27px;
  margin-bottom: 30px;
  color: #939598;
}
.template-selection-popup .popup-container .outline button {
  background: transparent;
  border: 0;
  margin-right: 15px;
  margin-bottom: 18px;
  padding: 0;
  border-radius: 3px;
}
/* .template-selection-popup .popup-container .outline button:nth-of-type(4n) {
  margin-right: 0;
} */
/* Template Global */
.template-selection-popup .config-template > h3 {
  font-size: 27px;
  color: #939598;
  margin-bottom: 30px;
}
.template-selection-popup .config-template .template-container {
  width: 68%;
  margin-right: 2%;
  display: inline-block;
  border: 2px dashed #bcbec0;
  padding: 60px 30px;
  position: relative;
  border-radius: 3px;
  margin-left: 1.5%;
}
.template-selection-popup
  .config-template
  .template-container
  .template-link-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  height: 32px;
  width: 32px;
  border: 0.1px solid #70707050;
  border-radius: 50%;
  padding: 4px;
  background: #efefef;
  box-shadow: 0 0 5px #70707015;

}
.template-selection-popup
  .config-template
  .template-container
  .template-link-btn:hover {
background: #909090;
  }
.template-link-img-btn {
  /* position: absolute;
  right: 6px;
  top: 6px;
  */
  float: right;
  margin-left: 6px;
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  padding: 4px;
  background: #efefef;
}
.template-selection-popup .config-template .template-container .dropzone {
  /* width: 327px;
  height: 315px; */
  width: 100%;
  height: 100%;
  border: 1px solid #939598;
  background-color: #e6e7e8;
  display: inline-block;
  border-radius: 3px;
}
.template-selection-popup .config-template .template-container .dropzone > img {
  /* margin-top: 105px; */
  margin-top: 240px;
  border-radius: 3px;
}
.template-selection-popup
  .config-template
  .template-container
  .input-container {
  border: 2px dashed #e6e7e8;
  display: inline-block;
  margin-left: 15px;
  width: calc(100% - 343px);
  padding: 15px 45px 15px 15px;
  vertical-align: top;
  position: relative;
}
.template-selection-popup
  .config-template
  .template-container
  .input-container
  .template-link-btn {
  right: 6px;
  top: 6px;
}
.template-selection-popup
  .config-template
  .template-container
  .input-container
  .show-question-btn {
  width: 100%;
  height: 48px;
  background: #e6e7e8;
  border: 0;
}
.image-option-component .upload-container,
.bild-option-component .upload-container,
.upload-option-component .upload-container {
  border: 2px dashed #bcbec0;
  margin-top: 15px;
  padding: 15px;
}

.bild-option-component .upload-container {
  margin-top: 0;
}
.bild-option-component .upload-container .image {
  display: block;
  width: 100%;
  padding-bottom: 51%;
  background-size: cover;
  background-position: center;
}
.image-option-component .upload-section img,
.bild-option-component .upload-section img,
.upload-option-component .upload-section img {
  display: block;
  margin: 15px auto;
}
.image-option-component .upload-section .choose-btn,
.bild-option-component .upload-section .choose-btn,
.upload-option-component .upload-section .choose-btn {
  background: #939598;
  font-size: 20px;
  border: 0;
  color: #e6e7e8;
  width: 65px;
  line-height: 15px;
  border-radius: 3px;
}
.image-option-component .upload-section .choose-btn:first-of-type,
.bild-option-component .upload-section .choose-btn:first-of-type,
.upload-option-component .upload-section .choose-btn:first-of-type {
  margin-right: 5px;
}
.image-option-component .images-container,
.upload-option-component .images-container {
  overflow: auto;
  max-height: 390px;
}
.upload-option-component .upload-container {
  position: relative;
}
.upload-option-component .upload-container .remove-btn {
  padding: 0;
  height: 16px;
  width: 16px;
  background: #d1d3d4;
  border-radius: 50%;
  border: 0;
  position: absolute;
  top: 6px;
  right: 6px;
}
.upload-option-component .upload-container .remove-btn img {
  vertical-align: 4px;
}

.image-option-component button.nav-button{
    position: absolute;
    display: block;
    z-index: 20000;
    left: 0px;
    top: 40%;
    padding: 0;
    border: 0;
    background: #d1d3d4bd;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.image-option-component button.nav-button:disabled{
  opacity: 0.4;
}

.image-option-component button.nav-button.nav-button-left{
  left: 0px;
  right:auto;
  /* content: url("./assets/arrow-small-right.svg"); */
  transform: rotate(180deg);
}

.image-option-component button.nav-button.nav-button-right{
  left: auto;
  right:0;
  /* content: url("./assets/arrow-small-right.svg"); */
}
.image-option-component button.nav-button.nav-button-top{
  top: 45px;
  bottom:auto;
  left:45%;
  /* content: url("./assets/arrow-small-right.svg"); */
  transform: rotate(270deg);
}

@media (max-width: 508px) {
  .image-option-component button.nav-button.nav-button-top.mbl-btn{
    position: relative;
    transform: rotate(270deg);
  }
  .image-option-component .upload-container, .bild-option-component .upload-container, .upload-option-component .upload-container{
    margin: 0;
    padding: 0;
  }
  }

.image-option-component button.nav-button.nav-button-bottom{
  top: auto;
  bottom:0;
  left:45%;
  /* content: url("./assets/arrow-small-right.svg"); */
  transform: rotate(90deg);
}
.image-option-component .images-container .images-overflow{
    overflow: hidden;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
    transition: transform 1s ease 0s;
}

/* Overerides*/
.image-option-component .images-container {
  overflow: hidden;
}

.template-container .image-option-component .images-container{
  overflow-x: visible;
  overflow-y: scroll;
}


.image-option-component .images-container .image-item {
  position: relative;
}

.image-option-component .images-container .image-item-horizontal,
.upload-option-component .images-container .image-item {
  display: inline-block;
  width: 315px;
}
.image-option-component
  .images-container
  .image-item-horizontal:not(:last-child),
.upload-option-component .images-container .image-item:not(:last-child) {
  margin-right: 15px;
}
.image-option-component .images-container .image-item-vertical {
  width: 100%;
  height: 263px;
}
.image-option-component
  .images-container
  .image-item-vertical:not(:last-child) {
  margin-bottom: 15px;
}
.image-option-component .images-container .image-item .image,
.upload-option-component .images-container .image-item .image {
  display: block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-bottom: 75%;
  margin-bottom: 25px ;
}
.image-option-component .images-container .image-item-vertical .image {
  padding-bottom: 215px;
  width: calc(100% - 90px);
  margin-left: 30px;
}
.upload-option-component .images-container .image-item .display-name {
  border: 0;
  border-bottom: 1px solid #707070;
  background: transparent;
  width: 100%;
  font-size: 17px;
  color: #939598;
  margin-top: 15px;
  margin-bottom: 15px;
}
.image-option-component .images-container .image-item .circle {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #939598;
  margin-bottom: 45px;
  margin-top: 15px;
  /* position: absolute;
  bottom: -34px;
  left: 10px; */
}
.image-option-component .images-container .image-item .square {
  display: inline-block;
  width: 11px;
  height: 11px;
  border: 1px solid #939598;
  margin-bottom: 45px;
  margin-top: 15px;
}
.image-option-component .images-container .image-item-vertical .circle {
  position: absolute;
  left: 6px;
  top: 0;
  margin: 0;
}
.image-option-component .images-container .image-item-vertical .square {
  position: absolute;
  left: 6px;
  top: 0;
  margin: 0;
}
.image-option-component .images-container .image-item .add-image-item {
  background: #e6e7e8;
  border-radius: 50%;
  border: 0;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: -10px;
  padding: 0;
  width: 24px;
  height: 24px;
}
.image-option-component .images-container .image-item-vertical .add-image-item {
  top: auto;
  bottom: 5px;
  right: 50%;
  margin-top: 0;
  margin-right: -12px;
}
.image-option-component .images-container .image-item .remove-image-item {
  padding: 0;
  height: 16px;
  width: 16px;
  background: #d1d3d4;
  border-radius: 50%;
  border: 0;
  position: absolute;
  top: 6px;
  right: 6px;
}
.image-option-component .images-container .image-item .edit-image-item {
  padding: 0;
  height: 23px;
  width: 23px;
  background: #d1d3d4;
  border-radius: 50%;
  border: 0;
  position: absolute;
  top: 30px;
  right: 6px;
}

.image-option-component
  .images-container
  .image-item-vertical
  .remove-image-item {
  right: 64px;
}
.image-option-component .images-container .image-item .remove-image-item img {
  vertical-align: 4px;
}
.image-option-component .images-container .image-item .option {
  position: absolute;
  background: transparent;
  padding: 0;
  right: 6px;
  bottom: 42px;
  border: 0;
}
.image-option-component .images-container .image-item-vertical .option {
  bottom: 90px;
  right: 15px;
}
.image-option-component .images-container .image-item .image-upload {
  height: 236.25px;
  background: #e6e7e8;
  overflow: auto;
}
.image-option-component .images-container .image-item .price-display {
  position: absolute;
  bottom: 6px;
  right: 6px;
  color: #939598;
  /* font-size: 17px; */
}
.image-option-component .images-container .image-item .price-display-image {
  position: absolute;
  bottom: 8px;
  color: #939598;
  left:30px;
}
.image-option-component .images-container .image-item .price-display-image-multiple {
  position: absolute;
  bottom: 25px;
  color: #939598;
  left:30px;
}
.image-option-component .images-container .image-item-vertical .price-display {
  right: 60px;
  bottom: 10px;
}
.image-option-component .images-container .image-item .x-guest {
  background: #e6e7e8;
  width: 127px;
  line-height: 32px;
  color: #939598;
  padding-left: 15px;
  position: absolute;
  left: 0;
  bottom: 6px;
  text-align: left;
  border-radius: 3px;
}
.image-option-component .images-container .image-item-vertical .x-guest {
  left: 30px;
}
.image-option-component .images-container .image-item-vertical .image-upload {
  height: 215px;
  width: calc(100% - 90px);
  margin-left: 30px;
}
.image-option-component .images-container .image-item .image-upload > img {
  display: block;
  margin: 60px auto 30px;
}
.image-option-component
  .images-container
  .image-item
  .image-upload
  .choose-btn {
  border: 0;
  background: #939598;
  font-size: 5px;
  width: 65px;
  height: 15px;
  color: #e6e7e8;
}
.image-option-component
  .images-container
  .image-item
  .image-upload
  .choose-btn:first-of-type {
  margin-right: 15px;
}
.image-selector-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.image-selector-modal.open {
  display: flex;
}
.image-selector-modal .selector-container {
  background: #e6e7e8;
  width: 1254px;
  height: 815px;
  max-height: 100vh;
  overflow: auto;
  position: relative;
  border-radius: 8px;
  border: 0.1px solid #90909030;
  box-shadow: 0 0 60px #00000025;
  /* overflow: hidden; */
}
.image-selector-modal .selector-container .close-btn {
  background: #939598;
}

.image-selector-modal .selector-container .close-btn:hover {
  background: #505050;
}

.image-selector-modal .selector-container .react-tabs__tab-list {
  list-style: none;
  padding: 0 0 0 45px;
  border-bottom: 1px solid #bcbec0;
  text-align: left;
}
.image-selector-modal
  .selector-container
  .react-tabs__tab-list
  .react-tabs__tab {
  display: inline-block;
  font-size: 30px;
  padding: 18px 0 12px;
  color: #939598;
  margin-bottom: -2px;
  cursor: pointer;
}
.image-selector-modal
  .selector-container
  .react-tabs__tab-list
  .react-tabs__tab{
  margin-right: 30px;
}
.image-selector-modal
  .selector-container
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected {
  border-bottom: 3px solid #939598;
  font-weight: bold;
}
.image-selector-modal
  .selector-container
  .react-tabs__tab-panel.react-tabs__tab-panel--selected {
  padding: 15px 45px 30px;
  height: 688px;
  
}
.image-option-component .image-selector-modal .selector-container .dropzone,
.upload-option-component .image-selector-modal .selector-container .dropzone {
  border: 1px solid #939598;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}
.image-option-component
  .image-selector-modal
  .selector-container
  .dropzone
  > img,
.upload-option-component
  .image-selector-modal
  .selector-container
  .dropzone
  > img {
  margin-top: 240px;
  height: auto;
}
.image-selector-modal .selector-container .media-library-header .type-select {
  -webkit-appearance: none;
  background: url(./assets/caret-up-down.svg) #d1d3d4 no-repeat right 17px top
    17px;
  color: #939598;
  line-height: 52px;
  width: 177px;
  border-radius: 3px;
  padding: 0 12px;
  border: 0;
  font-size: 17px;
}
.image-selector-modal .selector-container .media-library-header .search-input {
  line-height: 52px;
  background: url(./assets/magnifying-glass.svg) #d1d3d4 no-repeat right 17px
    top 17px;
  color: #939598;
  width: 400px;
  border: 0;
  border-radius: 3px;
  padding: 0 60px 0 12px;
}

.image-selector-modal .selector-container .media-library-container {
  margin-top: 30px;
  height: 510px;
  overflow-y: auto;
  text-align: left;
  margin-bottom: 30px;
}
.image-selector-modal .selector-container .media-library-container .media-item {
  display: inline-block;
  width: calc(25% - 22.5px);
  position: relative;
  border-radius: 3px;
  padding: 2px;
  border: 2px solid transparent;
}
.image-selector-modal
  .selector-container
  .media-library-container
  .media-item.selected {
  border: 2px solid #8eb0d1;
}
.image-selector-modal
  .selector-container
  .media-library-container
  .media-item:not(:nth-child(4n)) {
  margin-right: 30px;
}
.image-selector-modal
  .selector-container
  .media-library-container
  .media-item
  input {
  width: 1px;
  height: 1px;
  position: absolute;
  opacity: 0;
}
.image-selector-modal
  .selector-container
  .media-library-container
  .media-item
  input
  + span {
  display: block;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #8eb0d1;
  position: absolute;
  top: 6px;
  right: 6px;
}
.image-selector-modal
  .selector-container
  .media-library-container
  .media-item
  input:checked
  + span:after {
  content: "";
  display: block;
  width: 15px;
  height: 6px;
  border-left: 2px solid #939598;
  border-bottom: 2px solid #939598;
  transform: rotate(-45deg);
  position: absolute;
  left: 3px;
  top: 5px;
}
.image-selector-modal
  .selector-container
  .media-library-container
  .media-item
  .image {
  display: block;
  padding-bottom: 75%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.image-selector-modal
  .selector-container
  .media-library-container
  .media-item
  .file-name {
  background: #191919;
  color: #fff;
  font-size: 11px;
  padding: 0 6px;
}

.image-selector-modal .selector-container .media-library-footer .footer-btn {
  line-height: 49px;
  width: 210px;
  background: #939598;
  color: #e6e7e8;
  font-size: 16px;
  border: 0;
  border-radius: 3px;
}

.image-selector-modal .selector-container .media-library-footer .footer-btn:hover {
  background: #505050;
}

.image-selector-modal
  .selector-container
  .media-library-footer
  .footer-btn:first-child {
  margin-right: 15px;
  border-radius: 3px;
}
.radio-option-component {
  border: 2px dashed #bcbec0;
  padding: 6px;
}



.radio-option-component .radio-option-container {
  width: calc(100% - 60px);
}
.radio-option-component .template-link-btn {
  right: 21px !important;
}
.radio-option-component .question,
.text-option-component .question,
.image-option-component .question,
.upload-option-component .question ,
.datum-option-component.question 
{
  /* background: #d1d3d4; */
  width: 100%;
  border: 0;
  font-size: 24px;
  /* font-style: italic;
  font-family: "Crete Round"; */
  color: #939598;
  padding: 6px 12px;
}
.template-selection-popup
  .config-template
  .template-container
  .input-container
  .open-component-btn {
  width: 100%;
  background: #e6e7e8;
  height: 48px;
  border: 0;
  margin-top: 15px;
}
.text-option-component {
  position: relative;
}
.radio-option-component .radio-container {
  position: relative;
  margin-bottom: 7px;
}
.text-option-component .text-container {
  position: relative;
  margin-bottom: 5px;
}
.radio-option-component .radio-container:first-of-type {
  /* .text-option-component .text-container:first-of-type  */
  margin-top: 7px;
}
.radio-option-component .radio-container .remove-radio {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e6e7e8;
  position: absolute;
  right: -36px;
  top: 5px;
  border: 0;
}
.text-option-component .text-container .remove-text {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #e6e7e8;
  position: absolute;
  right: 4px;
  top: 0px;
  border: 0;
}
.radio-option-component .radio-container .circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #939598;
  margin: 6px 12px;
}
.radio-option-component .radio-container .square {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #939598;
  /* background: #939598; */
  margin: 6px 12px;
}
.radio-option-component .radio-container .radio-input {
  width: calc(100% - 48px);
  background: #e6e7e8;
  font-size: 17px;
  border: 0;
  /* padding: 8px 105px 8px 12px; */
  padding: 8px 95px 8px 12px;
  vertical-align: top;
  color: #939598;
  border-radius: 3px;
}
.text-option-component .text-container .text-input {
  width: calc(100% - 48px);
  background: #e6e7e8;
  font-size: 17px;
  border: 0;
  /* padding: 8px 105px 8px 12px; */
  padding: 8px 95px 0px 0px;
  vertical-align: top;
  color: #939598;
}
.text-option-component .text-container .text-input {
  width: 100%;
}
.radio-option-component .radio-container .option,
.text-option-component .text-container .option {
  position: absolute;
  background: transparent;
  padding: 0;
  right: 6px;
  top: 4px;
  border: 0;
}
.radio-option-component .radio-container .price-display {
  position: absolute;
  right: 44px;
  top: 8px;
  /* font-size: 17px; */
  color: #939598;
}
.text-option-component .text-container .price-display {
  position: absolute;
  right: 44px;
  top: 22px;
  /* font-size: 17px; */
  color: #939598;
}
.text-option-component {
  position: relative;
}
.radio-option-component .radio-container .add-radio {
  background: #e6e7e8;
  border-radius: 50%;
  border: 0;
  /* position: absolute; */
  left: 50%;
  margin-left: -12px;
  bottom: -10px;
  padding: 0;
  width: 24px;
  height: 24px;
}
.text-option-component .add-text {
  background: #e6e7e8;
  border-radius: 50%;
  border: 0;
  /* position: absolute; */
  left: 50%;
  /* margin-left: -12px; */
  margin-left: 2px;
  margin-bottom: 12px;
  bottom: -10px;
  padding: 0;
  width: 24px;
  height: 24px;
}
.radio-option-component .radio-container .x-guest,
.text-option-component .text-container .x-guest {
  line-height: 32px;
  padding: 0 12px;
  background: #e6e7e8;
  border-radius: 2px;
  width: calc(100% - 48px);
  margin-left: 48px;
  text-align: left;
  color: #939598;
  font-size: 17px;
  margin-top: 3px;
}
.text-option-component .text-container .x-guest {
  width: 100%;
  margin-left: 0;
}
.datum-option-component .datum-container,
.datum-option-component .time-container {
  text-align: left;
  margin-top: 15px;
  margin-left: 15px;
}
.datum-option-component .datum-container .date,
.datum-option-component .time-container .time {
  color: #e6e7e8;
  max-width: 209px;
  border-bottom: 1px solid #707070;
  font-size: 15px;
  background: url(assets/calendar.svg) no-repeat right 0 top 3px;
}
.datum-option-component .time-container .time {
  background: none;
}
.datum-option-component .datum-container span,
.datum-option-component .time-container span {
  font-family: "Crete Round";
  color: #939598;
  font-style: italic;
  font-size: 15px;
}
.table-option-component .table-container {
  width: 100%;
  border: 1px dashed #fff;
  background: #e6e7e8;
}
.table-option-component .table-container .checkbox-row .checkbox-column,
.table-option-component .table-container .column-row .checkbox-column {
  width: 30px;
  display: inline-block;
}
.table-option-component .table-container .checkbox-row .column,
.table-option-component .table-container .column-row .column {
  display: inline-block;
  text-align: center;
}
.table-option-component .table-container .column-row .column {
  border-right: 1px solid #939598;
  border-bottom: 1px solid #939598;
  height: 26px;
  vertical-align: top;
}
.table-option-component .table-container .column-row .column.selected {
  background: #939598;
  border-color: #e6e7e8;
}
.table-option-component .table-container .column-row .column:nth-of-type(2) {
  border-left: 1px solid #939598;
}
.table-option-component .table-container .column-row:nth-of-type(2) .column {
  border-top: 1px solid #939598;
}
.table-option-component .table-container .checkbox-row .column input,
.table-option-component .table-container .column-row .column input {
  width: 100%;
  background: transparent;
  border: 0;
  text-align: center;
  color: #3f4041;
}
.table-option-component .table-container .column-row .column .placeholder-type {
  color: #b1b1b1;
}
.template-selection-popup .config-template .template-container .second-radio {
  background: #e6e7e8;
  padding: 45px;
  position: relative;
  border-radius: 3px;
}
.template-selection-popup
  .config-template
  .template-container
  .second-radio
  .link-btn {
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #939598;
  position: absolute;
  right: 5px;
  top: 5px;
}
.template-selection-popup
  .config-template
  .template-container
  .second-radio
  .question {
  width: 100%;
  background: #d1d3d4;
  /* font-family: "Crete Round";
  font-style: italic; */
  color: #939598;
  padding: 0 12px;
  border: 0;
  font-size: 24px;
  line-height: 52px;
  margin-bottom: 15px;
}
.template-selection-popup
  .config-template
  .template-container
  .second-radio
  .radio-container {
  position: relative;
}
.template-selection-popup
  .config-template
  .template-container
  .second-radio
  .radio-container
  span {
  display: inline-block;
  background: #939598;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 15px 15px 0 0;
}
.template-selection-popup
  .config-template
  .template-container
  .second-radio
  .radio-container
  .radio-input {
  width: calc(100% - 39px);
  background: #d1d3d4;
  vertical-align: top;
  border: 0;
  line-height: 52px;
  font-size: 17px;
  color: #939598;
  padding: 0 12px;
}
.radio-input1 {
  width: calc(100% - 0px);
  background: #d1d3d4;
  vertical-align: top;
  border: 0;
  line-height: 52px;
  font-size: 17px;
  color: #939598;
  padding: 0 12px;
}
.template-selection-popup
  .config-template
  .template-container
  .second-radio
  .radio-container
  .radio-options-btn {
  position: absolute;
  right: 9px;
  top: 10px;
  background: transparent;
  border: 0;
}
.template-selection-popup
  .config-template
  .template-container
  .second-radio
  .radio-container
  .add-radio {
  width: 24px;
  height: 24px;
  background: #939598;
  border: 0;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  left: calc(50% - 12px);
  bottom: -12px;
}
.template-selection-popup
  .config-template
  .template-container
  .second-radio
  .radio-container
  .add-radio
  img {
  vertical-align: -2px;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container {
  width: 100%;
  padding: 15px;
  position: relative;
  /* background: #e6e7e8; */
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box-container.slim {
  height: 51px;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box-container.space-top {
  margin-top: 30px;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box-container.space-bottom {
  /* margin-bottom: 30px; */
  margin-bottom: 0px;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-expand-right {
  position: absolute;
  /* right: -12px; */
  right: -23px;
  top: 50%;
  border-radius: 50%;
  padding: 0;
  width: 24px;
  border: 0;
  margin-top: -12px;
  display: none;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container:hover
  .layout-expand-right {
  display: block;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-expand-bottom {
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  border: 0;
  border-radius: 50%;
  padding: 0;
  display: none;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container:hover
  .layout-expand-bottom {
  display: block;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-expand-top {
  position: absolute;
  top: 3px;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  border: 0;
  border-radius: 50%;
  padding: 0;
  z-index: 1;
  display: none;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container:hover
  .layout-expand-top {
  display: block;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box-vertical {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box-vertical
  .layout-box {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box-vertical:not(:last-of-type) {
  margin-right: 30px;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box-vertical
  .layout-box:not(:last-of-type) {
  margin-bottom: 30px;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box
  .layout-box-btn {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 15px 0px;
  background: #e6e7e8;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 3px;
  box-shadow: 0 0 10px #70707025;
  border: 0.1px solid #70707030
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box
  .layout-box-btn:hover {
    background: #b7babe;
    
  }
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box
  .layout-box-btn.selected {
  background: #939598;
  box-shadow: 0 0 10px #70707050;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box
  .layout-remove {
  padding: 0;
  height: 16px;
  width: 16px;
  background: #d1d3d4;
  border-radius: 50%;
  border: 0;
  position: absolute;
  /* top: 6px;
  right: 6px; */
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box
  .layout-remove
  img {
  vertical-align: 4px;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box
  .layout-merge {
  padding: 0;
  border: 0;
  border-radius: 50%;
  width: 24px;
  background: #e6e7e8;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: -26px;
  display: none;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container:hover
  .layout-box
  .layout-merge {
  display: block;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box
  .layout-merge-default {
  padding: 0;
  border: 0;
  border-radius: 50%;
  width: 24px;
  background: #e6e7e8;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  bottom: -27px;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container
  .layout-box
  .layout-expand-default {
  padding: 0;
  border: 0;
  border-radius: 50%;
  width: 24px;
  background: #e6e7e8;
  position: absolute;
  bottom: -12px;
  margin-left: -12px;
  left: 50%;
  display: none;
}
.template-selection-popup
  .config-template
  .template-container
  .layout-row-container:hover
  .layout-box
  .layout-expand-default {
  display: block;
}
.template-selection-popup .config-template .add-text-box {
  width: 100%;
  background: #e6e7e8;
  height: 48px;
  border: 0;
}
.template-selection-popup .config-template .rdw-editor-toolbar {
  background: #e6e7e8;
}

.template-selection-popup
  .config-template
  .rdw-editor-toolbar
  .rdw-option-wrapper {
  background: #e6e7e8;
}
.template-selection-popup
  .config-template
  .DraftEditor-root
  > div
  > .public-DraftEditor-content {
  background: #e6e7e8;
  padding: 15px;
}
.template-selection-popup .config-template .public-DraftStyleDefault-block {
  margin: 0;
}
.template-selection-popup .config-template .component-container {
  width: 27%;
  border: 2px dashed #bcbec0;
  border-radius: 3px;
  display: inline-block;
  padding-top: 40px;
  padding-bottom: 45px;
  vertical-align: top;
  margin-right: 1.5%;
}
.template-selection-popup
  .config-template
  .component-container
  .component-selector {
  width: 280px;
  padding: 0;
  line-height: 42px;
  text-align: left;
  background: #e6e7e8;
  border: 0;
  border-radius: 3px;
  padding-left: 15px;
  font-size: 17px;
  color: #939598;
  margin-bottom: 15px;
  box-shadow: 0 0 5px #70707025;
  border: 0.1px solid #70707030;
}
.template-selection-popup
  .config-template
  .component-container
  .component-selector:hover {
    background-color: #b7babe;
    color: #fcfcfc;
  }

.template-selection-popup
  .config-template
  .component-container
  .component-selector.selected {
  color: #e6e7e8;
  background: #939598;
}
.template-selection-popup
  .config-template
  .component-container
  .component-selector
  > img {
  float: right;
  margin-top: 18px;
  margin-right: 3px;
  transform: rotate(90deg);
}
.template-selection-popup
  .config-template
  .component-container
  .component-selector.selected
  > img {
  transform: rotate(180deg);
}
.template-selection-popup
  .config-template
  .component-container
  .component-selector
  .right-dot {
  float: right;
  width: 42px;
  border-left: 1px solid #d1d3d4;
  height: 42px;
}
.template-selection-popup
  .config-template
  .component-container
  .component-selector
  .right-dot
  span {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 13px;
  margin-left: 13px;
  border: 1px solid #939598;
}
.template-selection-popup
  .config-template
  .component-container
  .component-selector.selected
  .right-dot
  span {
  border-color: #d1d3d4;
  background: #d1d3d4;
}
.template-selection-popup
  .config-template
  .component-container
  .component-radio-expand,
.template-selection-popup
  .config-template
  .component-container
  .component-datum-expand,
.template-selection-popup
  .config-template
  .component-container
  .component-text-expand,
.template-selection-popup
  .config-template
  .component-container
  .component-upload-expand,
.template-selection-popup
  .config-template
  .component-container
  .component-table-expand {
  text-align: left;
  width: 280px;
  margin: 0 auto;
  display: block;
}
.template-selection-popup
  .config-template
  .component-container
  .component-datum-expand
  > div {
  display: inline-block;
  margin-right: 15px;
}
.template-selection-popup
  .config-template
  .component-container
  .component-radio-expand
  h6,
.template-selection-popup
  .config-template
  .component-container
  .component-datum-expand
  h6,
.template-selection-popup
  .config-template
  .component-container
  .component-text-expand
  h6,
.template-selection-popup
  .config-template
  .component-container
  .component-table-expand
  h6 {
  font-family: "Crete Round";
  font-size: 10px;
  color: #939598;
  font-style: italic;
}
.template-selection-popup
  .config-template
  .component-container
  .component-radio-expand
  select,
.template-selection-popup
  .config-template
  .component-container
  .component-datum-expand
  select,
.template-selection-popup
  .config-template
  .component-container
  .component-text-expand
  select,
.template-selection-popup
  .config-template
  .component-container
  .component-upload-expand
  select,
.template-selection-popup
  .config-template
  .component-container
  .component-table-expand
  select {
  -webkit-appearance: none;
  color: #939598;
  padding: 10px 18px 10px 12px;
  border: 0;
  margin-bottom: 15px;
  background-color: #e6e7e8;
  background-image: url(assets/arrow-small-down.svg);
  background-repeat: no-repeat;
  background-position: right 6px top 18px;
}
.template-selection-popup
  .config-template
  .component-container
  .component-radio-expand
  select,
.template-selection-popup
  .config-template
  .component-container
  .component-text-expand
  select,
.template-selection-popup
  .config-template
  .component-container
  .component-upload-expand
  select,
.template-selection-popup
  .config-template
  .component-container
  .component-table-expand
  select {
  width: 100%;
}
.template-selection-popup
  .config-template
  .component-container
  .component-upload-expand
  .upload-select-options {
  color: #939598;
  font-size: 17px;
}
.template-selection-popup
  .config-template
  .component-container
  .component-upload-expand
  .upload-select-options
  input {
  width: 45px;
  line-height: 21px;
  background: #e6e7e8;
  border-radius: 3px;
  border: 0;
  padding: 0 12px;
  font-size: 12px;
  color: #939598;
}
.template-selection-popup
  .config-template
  .component-container
  .component-upload-expand
  .download-select-options
  input {
  width: 100%;
  border: 0;
  border-radius: 3px;
  background: #e6e7e8;
  line-height: 48px;
  padding: 0 12px;
  font-size: 14px;
  color: #939598;
  margin-bottom: 15px;
}
.template-selection-popup
  .config-template
  .component-container
  .component-text-expand
  input[type="number"] {
  color: #939598;
  border: 0;
  margin-bottom: 15px;
  background-color: #e6e7e8;
  padding: 10px 12px;
  width: 100%;
}
.template-selection-popup .config-template .option-component {
  /* padding-top: 30px; */
  padding-bottom: 1px;
  /* padding-bottom: 30px; */
  position:sticky;
  top:0;
}
.template-selection-popup .config-template .option-component > button {
  width: 54px;
  height: 51px;
  background: #e6e7e8;
  color: #939598;
  padding: 0;
  border: 0;
  font-size: 27px;
  border-radius: 3px;
  box-shadow: 0 0 5px #70707025;
  border: 0.1px solid #70707050;
}
.template-selection-popup .config-template .option-component .price-selection {
  margin-right: 15px;
  border-radius: 3px;
  box-shadow: 0 0 5px #70707025;
  border: 0.1px solid #70707050;
}
.template-selection-popup
  .config-template
  .option-component
  .price-selection.selected {
  color: #d1d3d4;
  background: #939598;
  border-radius: 3px;
  box-shadow: 0 0 5px #70707025;
  border: 0.1px solid #70707050;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-selection.selected {
  background: #939598;
}
.template-selection-popup .config-template .option-component .price-options {
  margin-top: 30px;
}
.template-selection-popup .config-template .option-component .tag-options {
  margin-top: 30px;
  
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-input {
  position: relative;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-input:after {
  /* content: "€"; */
  font-size: 17px;
  color: #939598;
  position: absolute;
  left: calc(50% - 78px);
  top: 5px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-input
  .netto-brutto
  input::before {
  /* content: "€"; */
  font-size: 17px;
  color: #939598;
  position: absolute;
  left: calc(50% - 78px);
  top: 5px;
}


.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-input
  input {
  padding-left: 30px;
  background: #e6e7e8;
  border: 0;
  color: #939598;
  font-size: 17px;
  line-height: 32px;
  width: 186px;
  border-radius: 3px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-input
  .netto-brutto
  input {
  padding-left: 10px;
  background: #e6e7e8;
  border: 0;
  color: #939598;
  font-size: 17px;
  line-height: 32px;
  width: 99px;
  border-radius: 3px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-input
  .clear-price {
  position: absolute;
  top: 3px;
  background: transparent;
  border: 0;
  right: calc(50% - 92px);
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .plus-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #e6e7e8;
  margin: 15px auto;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-option-btn-container {
  position: relative;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-option-btn-container
  .clear-option {
  background: transparent;
  position: absolute;
  right: calc(50% - 92px);
  top: 2px;
  border: 0;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options {
  margin-top: 20px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .left-text {
  width: 40%;
  display: inline-block;
  text-align: left;
  padding-left: 20px;
  font-size: 17px;
  color: #939598;
  line-height: 32px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options {
  display: inline-block;
  width: 60%;
  vertical-align: top;
  text-align: left;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .small-input,
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .unit {
  width: 45px;
  line-height: 22px;
  font-size: 12px;
  border: 0;
  background: #e6e7e8;
  border-radius: 3px;
  text-align: center;
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 6px;
  margin-bottom: 8px;
  color: #939598;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .small-input {
  margin-right: 4px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .unit {
  text-align: left;
  padding: 0 6px;
  width: 185px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .calculate {
  margin-bottom: 0;
  /* added */
  /* margin-top: 5px; */
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .calculate
  input,
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .more
  input,
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .alle
  input {
  height: 1px;
  width: 1px;
  opacity: 0;
  display: none;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .calculate
  input
  + span,
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .more
  input
  + span,
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .alle
  input
  + span {
  width: 80px;
  /* height: 21px; */
  line-height: 20px;
  background: #e6e7e8;
  display: inline-block;
  border-radius: 3px;
  font-size: 12px;
  color: #939598;
  vertical-align: top;
  margin-top: 3px;
  margin-right: 3px;
  text-align: center;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .more
  input
  + span {
  width: 70px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .alle
  input
  + span {
  width: 24px;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .calculate
  input:checked
  + span,
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .more
  input:checked
  + span,
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .alle
  input:checked
  + span {
  background: #939598;
  color: #e6e7e8;
}
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .last-input {
  width: 42px;
  background: #e6e7e8 url(./assets/euro-small.svg) no-repeat left 3px top 1px;
  border: 0;
  border-radius: 3px;
  height: 21px;
  font-size: 12px;
  color: #939598;
  /* margin-left: 6px; */
  padding-left: 12px;
}

@media only screen and (max-width : 1300px) {
  .template-selection-popup
  .config-template
  .option-component
  .price-options
  .numerically-options
  .right-options
  .last-input{
    width:25px;
  }
  }
.template-selection-popup
  .config-template
  .option-component
  .price-options
  .price-option-btn {
  width: 278px;
  line-height: 30px;
  background: #e6e7e8;
  border-radius: 3px;
  display: block;
  margin: 0 auto 12px;
  font-size: 17px;
  color: #939598;
  border: 0.1px solid #70707030;
  box-shadow: 0 0 5px #70707025;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select {
  width: 186px;
  margin: 0 auto;
  border: 0;
  border-radius: 3px;
  margin-bottom: 15px;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__control {
  background: #e6e7e8 url(assets/magnifying-glass.svg) no-repeat right 12px top
    7px;
  min-height: 32px;
  box-shadow: none;
  border: 0;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__value-container {
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__placeholder {
  font-size: 17px;
  color: #939598;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__single-value {
  font-size: 17px;
  color: #939598;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__indicators {
  display: none;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__menu {
  border: 0;
  margin-top: 3px;
  box-shadow: none;
  background: #e6e7e8;
  border-radius: 3px;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__menu-list {
  border: 0;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__option {
  font-size: 15px;
  color: #939598;
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__option--is-focused {
  background: #939598;
  color: #efefef;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__option--is-selected {
  background: #e6e7e8;
  color: #939598;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .tag-select
  .tag__input {
  color: #939598;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .add-tag {
  background: #e6e7e8;
  border: 0;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  padding: 0;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .select-container {
  position: relative;
}
.template-selection-popup
  .config-template
  .option-component
  .tag-options
  .select-container
  .remove-tag {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #e6e7e8;
  position: absolute;
  right: calc(50% - 130px);
  top: 1px;
  border: 0;
}

.layout-row-container {
  width: 100%;
  min-height: 200px;
  /* padding: 15px; */
  position: relative;
}

.layout-box-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.validationMsg {
  border: 1px solid #f3b1b1;
  background-color: #ecbebe;
  color: red;
  width: 250px;
  margin: 2px 45px 0px;
  font-size: 12px;
}
.slim {
  height: 51px;
}

.space-top {
  margin-top: 30px;
}

.space-bottom {
  margin-bottom: 30px;
}

.layout-box-vertical {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.layout-box {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.layout-box-vertical:not(:last-of-type) {
  margin-right: 30px;
}

.layout-box:not(:last-of-type) {
  margin-bottom: 30px;
}

.layout-box-btn {
  width: 100%;
  height: 100%;
  border: 0;
  background: #00000000;
}

.selected {
  background: #939598;
}

.layout-remove {
  padding: 0;
  height: 16px;
  width: 16px;
  background: #d1d3d4;
  border-radius: 50%;
  border: 0;
  position: absolute;
  top: -9px;
  right: -4.5px;
}

.layout-remove img {
  vertical-align: 4px;
}
.date-time-input {
  border: 0;
  padding: 0;
  margin: 0;
  background: #00000000;
  width: 100%;
}

.table.date-time-input {
  border: 0;
  padding: 0 10px;
  margin: 0;
  background: #00000000;
  width: 100%;
  /* font-family: "Crete Round", sans-serif; */
  text-align: center;
}

.cell.date-time-input {
  border: 0;
  padding: 0 5px;
  margin: 0;
  background: #00000000;
  width: 100%;
  /* font-family: Roboto, serif; */
}

.header.date-time-input {
  border: 0;
  padding: 0 5px;
  margin: 0;
  background: #00000000;
  width: 100%;
  /* font-size: 14px; */
}

form.preview input::placeholder {
  color: lightgrey;
}

.edit-template {
  float: right;
  border: none;
  margin-top: 10px;
  margin-right: 10px;
}
/*
.public-DraftStyleDefault-block .public-DraftStyleDefault-ltr{
  padding-right: 15px;
} */

/* .wrapperClassName{
  padding-right: 5px;
} */

.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__header {
  background-color: #ffffff !important;
}

.react-datepicker__day--selected {
  background-color: lightgrey !important;
  color: #000000 !important;
}

.buttons.dropdown-menu {
  max-height: 200px !important;
  overflow: auto !important;
}
.highcharts-credits {
  display: "none" !important;
}

.dropdown1.dropdown-toggle {
  width: 150px !important;
  font-size: 15px;
  border: "0.1px solid #90909025" !important;
}

.dropdown1.dropdown-toggle.btn.btn-secondary {
  height: 40px !important;
  border-radius: 3px;
  border: "0.1px solid #90909025" !important;
	box-shadow: 0px 0px 5px #dadada
}

/* guest price */
 .guests-price .price-selection {
  margin-right: 15px;
}
.guests-price > button {
  width: 54px;
  height: 51px;
  background: #e6e7e8;
  color: #939598;
  padding: 0;
  border: 0;
  font-size: 27px;
}
.config-right-bar .guests-price .price-selection.selected {
  color: #d1d3d4;
  background: #939598;
  margin-left: 24px;

}
.guests-price .price-options {
  margin-top: 30px;
}
.guests-price .tag-options {
  margin-top: 30px;
}
.guests-price .price-options .price-input {
  position: relative;
}
.guests-price .price-options .price-input:after {
  /* content: "€"; */
  font-size: 17px;
  color: #939598;
  position: absolute;
  left: calc(50% - 80px);
  top: 5px;
}
.currency-€:after
{
  content:"€";  
}

.currency-kr:after
{
  content:"kr.";  
}
.currency-₹:after
{
  content:"₹";  
}
.currency-₪:after
{
  content:"₪";  
}
.currency-NOK:after
{
  content:"NOK";  
}
.currency-Zł:after
{
  content:"Zł";  
}
.currency-₽:after
{
  content:"₽";  
}
.currency-Skr:after
{
  content:"Skr";  
}
.currency-CHF:after
{
  content:"CHF";  
}
.currency-₺:after
{
  content:"₺";  
}
.currency-£:after
{
  content:"£";  
}

.currency-dollor:after
{
  content:"$";  
}



.guests-price .price-options .price-input input {
  padding-left: 50px;
  background: #e6e7e8;
  border: 0;
  color: #939598;
  font-size: 17px;
  line-height: 32px;
  width: 186px;
  border-radius: 3px;
}
.guests-price .price-options .price-input .clear-price {
  position: absolute;
  top: 3px;
  background: transparent;
  border: 0;
  right: calc(50% - 92px);
}
.guests-price .price-options .plus-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #e6e7e8;
  margin: 15px auto;
}
.guests-price .price-options .price-option-btn-container {
  position: relative;
}
.guests-price .price-options .price-option-btn-container .clear-option {
  background: transparent;
  position: absolute;
  right: calc(50% - 92px);
  top: 2px;
  border: 0;
}
.guests-price .price-options .numerically-options {
  margin-top: 20px;
}
.guests-price .price-options .numerically-options .left-text {
  width: 40%;
  display: inline-block;
  text-align: left;
  padding-left: 10px;
  font-size: 13px;
  color: #939598;
  line-height: 32px;
}
.guests-price .price-options .numerically-options .right-options {
  display: inline-block;
  width: 60%;
  vertical-align: top;
  text-align: left;
  padding-left: 15px;
}
.guests-price .price-options .numerically-options .right-options .small-input,
.guests-price .price-options .numerically-options .right-options .unit {
  width: 45px;
  line-height: 22px;
  font-size: 12px;
  border: 0;
  background: #e6e7e8;
  border-radius: 3px;
  text-align: center;
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 6px;
  margin-bottom: 8px;
  color: #939598;
}
.guests-price .price-options .numerically-options .right-options .small-input {
  margin-right: 4px;
}
.guests-price .price-options .numerically-options .right-options .unit {
  text-align: left;
  padding: 0 6px;
  width: 185px;
}
.unit1 {
  text-align: left;
  background: #e6e7e8;
    border: none;
    margin-bottom: 20px;
    width: 280px;
    height: 40px;
    color: #939598;
}
.guests-price .price-options .numerically-options .right-options .calculate {
  margin-bottom: 0;
  /* added */
  /* margin-top: 5px; */
}
.guests-price
  .price-options
  .numerically-options
  .right-options
  .calculate
  input,
.guests-price .price-options .numerically-options .right-options .more input,
.guests-price .price-options .numerically-options .right-options .alle input {
  height: 1px;
  width: 1px;
  opacity: 0;
  display: none;
}
.guests-price
  .price-options
  .numerically-options
  .right-options
  .calculate
  input
  + span,
.guests-price
  .price-options
  .numerically-options
  .right-options
  .more
  input
  + span,
.guests-price
  .price-options
  .numerically-options
  .right-options
  .alle
  input
  + span {
  width: 80px;
  /* height: 21px; */
  line-height: 20px;
  background: #e6e7e8;
  display: inline-block;
  border-radius: 3px;
  font-size: 12px;
  color: #939598;
  vertical-align: top;
  margin-top: 3px;
  margin-right: 3px;
  text-align: center;
}
.guests-price
  .price-options
  .numerically-options
  .right-options
  .more
  input
  + span {
  width: 70px;
}
.guests-price
  .price-options
  .numerically-options
  .right-options
  .alle
  input
  + span {
  width: 36px;
}
.guests-price
  .price-options
  .numerically-options
  .right-options
  .calculate
  input:checked
  + span,
.guests-price
  .price-options
  .numerically-options
  .right-options
  .more
  input:checked
  + span,
.guests-price
  .price-options
  .numerically-options
  .right-options
  .alle
  input:checked
  + span {
  background: #939598;
  color: #e6e7e8;
}

/* .last-input1{
  font-size: 10px;
  color: #939598;
  padding-left: 12px;

} */

.guests-price .price-options .numerically-options .right-options .last-input1{
  position: relative;
}

.guests-price .price-options .numerically-options .right-options .last-input1:after{
  font-size: 10px;
  color: #939598;
  position: absolute;
  left: calc(50% - 30px);
  top: 6px;
}

.guests-price .price-options .numerically-options .right-options .last-input{
  width: 60px;
  /* background: #e6e7e8 url(./assets/euro-small.svg) no-repeat left 3px top 1px; */
  background: #e6e7e8;
  border: 0;
  border-radius: 3px;
  height: 21px;
  font-size: 12px;
  color: #939598;
  /* margin-left: 6px; */
  padding-left: 25px;
}

.guests-price .price-options .price-option-btn {
  width: 278px;
  line-height: 30px;
  background: #e6e7e8;
  border-radius: 3px;
  display: block;
  margin: 0 auto 12px;
  font-size: 17px;
  color: #939598;
  border: 0;
}

.template-selection-popup .config-template .DraftEditor-root > div > .public-DraftEditor-content{
  background-color: transparent;
}

.template-selection-popup
  .config-template
  .template-container
  .change-image-item {
  position: absolute;
  right: 42px;
  top: 8px;
  height: 28px;
  width: 27px;
  border: 0;
  border-radius: 50%;
  padding: 4px;
  background: #efefef;
}


.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 15px;
  height: 15px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
      border-bottom-color: transparent;
      border-bottom-style: solid;
      border-bottom-width: 10px;
  opacity: .5;
  transition: opacity .6s ease;
  border-radius: 50%;
}

.carousel-indicators {
  bottom:-10px
}

.text-div{

border: none;
overflow: hidden;
resize: none;
-moz-box-shadow: inset  white;
-webkit-box-shadow: inset  white;
box-shadow: inset  white;
}
/* .carousel-caption {
  height: 120px;
overflow-y: auto;
padding-top:0px;
margin-bottom: 16px;
} */

/* tooltip arrow */
.arrow {
    display: none !important;
}

.swal-text{
  text-align: center;
}
.swal-footer {
  text-align: center;
}

.carousel-indicators li {
  /* background-color: #999 !important; */
  /* filter: drop-shadow(0 0 10px #0003); */
  background-color: rgba(59, 55, 55, 0.75)!important;
}

.carousel-indicators .active {
  background-color: #444 !important;
}
.carousel-control-next-icon {
  border-radius:50%;
  background-color:black;
  width: 30px;
  height: 30px;
  background-image: none;
  content: url("./assets/arrow-small-right.svg");
  padding:6px;
}

.carousel-control-prev-icon {
  border-radius:50%;
  background-color:black;
  width: 30px;
  height: 30px;
  background-image: none;
  content: url("./assets/arrow-small-right.svg");
  transform: rotate(180deg);
  padding:6px;
}
.text-div{
 
  /* border: 0px solid #ccc; */
  /* font: medium -moz-fixed; */
  border: none;
  overflow: hidden;
  resize: none;
  -moz-box-shadow: inset  white;
  -webkit-box-shadow: inset  white;
  box-shadow: inset  white;
  }
  .right-bar .euro-right-bar-collapse {
    position: absolute;
    left: 160px;
    top: 15px;
    background: transparent;
    border: 0;
    outline: none;
    transition: 0.3s;
    /* transform: rotate(180deg); */
  }
  .right-bar .euro-right-bar-collapse.collapsed {
    transform: rotate(0deg);
    left: -110px;
  }

  .loader.loader-text{
    text-align: center;
    display: block;
    padding: 50px;
    color:#939598;
    /* transform: translateY(-50%); */
    font-size: 40px;
  }
  .rdw-fontfamily-optionwrapper{
    width: 155px !important;
    overflow-y: auto !important;
  }

@media only screen and (max-width : 1024px) {
  .Toastify__toast {
    margin-bottom: 10px;
  }

  .Toastify__toast-body{
    padding:10px;
  }
}
.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 250px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
 
}
.rdw-link-modal-btn{
  width:90px
}

.image-container{
  width:100%;
  height: 100%;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
}

.image-container img{
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: auto;
  width: 100% !important;
  max-height: 100% !important; 
  transform: translateY(-50%) translateX(-50%);
  object-fit: contain;
  object-position: 50% 50% !important;
}


.carousel-caption{
  background: rgba(255,255,255,0.8);
  padding: 5px;
  bottom:15%;
}

.carousel-caption h3{
  font-size: 20px;
}

.carousel-caption p{
  font-size: 16px;
}

.carousel-indicators{
  bottom: 0;
  padding: 0;
  padding-bottom: 10px;
}


@media only screen and (max-width : 1024px) {
  .carousel{
    padding-bottom: 78px;
  }

  .image-container{
    padding-top: 45%;
  }
  
  .carousel-caption{
    position: relative;
    right: 0;
    left:0;
    background: transparent;
    padding: 5px;
  }

  .carousel-indicators {
      bottom: 20px;
      padding: 0;
          padding-bottom: 0px;
      padding-bottom: 0;
      z-index: 6;
  }

  .carousel-control-next, .carousel-control-prev{
    height: calc(100% - 68px);
    z-index: 6;
    
  } 

  .carousel-caption h3{
    font-size: 16px;
    padding: 5px;
    margin: 0;
  }
  
  .carousel-caption p{
    font-size: 14px;
  }

}
.plan_preview_nav .download{
  padding:5px;
  margin: 0;
  margin-right: 10px;
  position: relative;
  top:50%;
  transform:translateY(-50%);
}
.plan_preview_nav .download img{
  height: 25px;
  width: 25px;
}
/* #page-header{
  counter-increment: page;
  content: counter(page);
  position: relative
} */

.main-content{
  /* margin-left: -950px; */
  margin-top:0px !important;
  page-break-before: always;
  margin-bottom: 100pt;
 overflow: visible !important;
  /* page-break-after: avoid; */
  /* height: 90% !important;  */
   /* page-break-inside: auto; */
  /* page-break-before:auto; */
}
.topHeaderContent{
  width: 970px;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%,0%);
}
#content {
  display: table;
}

.pageNumber {
  display: table-footer-group;
}


.header-space {
  /* position: relative;  */
  /* top: 191px; */
  /* left: 477px;
  margin-left: 20px; */

position: relative;
    height: 150px;
}
.pageNumber:after {
  
  counter-increment: pages;
  content: "Seite " counter(page) " von " counter(pages);
}
.inner{
  display:flex;
  height: 60px;
  width: 800px;
  /* outline:1px solid black; */
  /* margin-left:230px; */
  /* justify-content:center */
  justify-content: space-between;
}
.invoiceContainer{
  position: absolute;
  top: 60%;
  right: 50%;
  transform: translate(50%,0%);
}
.headerImage
{
  position: absolute;
  right: 30px;
  margin-top: 20px;
  width: 330px;
  height: 165px;
}

@media print { 
  .headerImage
{
  /* position: absolute;
  right: 0;
  margin-top: -174px;
  width: 340px;
  height: 150px; */

  position: absolute;
  right: 30px;
  margin-top: -174px;
  width: 330px;
  height: 165px;
}
/* .site-main-print{
  padding-right: 200px;
  padding-left:300px ;

} */
.planUrl{
  position: relative;
  width: 800px;
  /* display: block; */
  margin-top: 180px;
  left:260px ;
}
.draggedItems{
  position: absolute;
}
  .closingText{
    /* top: 1100px; */
    left: -150px;
    width: 574px;
    height: 240px;
    /* position: relative */
  }
  .inner{
    display:flex;
    height: 60px;
    width: 800px;
    /* outline:1px solid black; */
    /* margin-left:50px; */
    /* justify-content:center; */
    justify-content: space-between;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .pageNumber:after {
  
    counter-increment: pages;
    content: "Seite " counter(page) " von " counter(pages);
  }
  .main-content{
    /* margin-top:150px !important; */
    page-break-inside: auto;
    overflow: visible !important;
    /* page-break-before: auto; */
    /* height: 90% !important; */
    /* height:100vh; */
    /* margin-left: -960px; */
    /* margin-bottom: 100pt; */
     /* page-break-inside: auto; */
    /* page-break-before:auto; */
  }
  .invoiceContainer{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
  }

  /* .inner:after {
    counter-increment: pageTotal; 
    counter-increment: page;
    content: counter(page) "/" counter(pages);
} */
/* @page {
  @top-right {
      content: "Page " counter(page) " of " counter(pages);
   }
} */
 
  .footer-space {
  position: fixed;
  bottom:0;
  /* bottom: 90px; */
  /* overflow: visible !important; */
  page-break-inside: avoid;
  /* bottom: 5pt; */
  /* height: 100pt; */
  /* z-index:1000; */
  /* margin-left: -400px; */
  } 

  /* display: flex;
  height: 60px;
  width: 790px; 
  h
  }
  #content {
    display: table;
}

/* .pageNumber {
    display: table-footer-group;
} */
/* 
.pageNumber:after {
    counter-increment: page;
    content: counter(page);
} */
/* @page {
  @bottom-right {
   content: counter(page) " of " counter(pages);
  }
} */
  
 

 
 @page {
  margin-top: 3cm;
  margin-bottom: 3cm;
  /* margin-left: 2cm;
  margin-right: 2cm; */
  /* @bottom-left {
    content: counter(page) ' of ' counter(pages);
  } */
  /* @bottom-right-corner {
    content: "Page " counter(page);
  } */
}

tr    
{ 
  display: table-row-group;
  page-break-inside:avoid; 
  page-break-after:auto;
}
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-row-group }
  tfoot { display:table-row-group; }
  /* tfoot { display:table-row-group;position: absolute;bottom: 0; } */
  tbody {page-break-inside: auto;}
  /* html, body {
    min-height:auto; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: visible;
  } */
  div {
    border: 1px solid rgba(0,0,0,0)
  }
}



@media  (min-width:948px) and (max-width: 1206px) {
  .input-boxes {
    /* max-width: 385px; */
    width: 120%;
   margin-left: -100px;
  }
}
@media  (min-width:828px) and (max-width: 948px) {
  .input-boxes {
    /* max-width: 385px; */
    width: 200%;
   margin-left: -190px;
  }
}
.input-boxes {
  /* max-width: 385px; */
  width: 100%;
 margin-left: 0px;
}
.input-min-max{
  width: 45px;
line-height: 21px;
background: #e6e7e8;
border-radius: 3px;
border: 0;
padding: 0 12px;
font-size: 12px;
color: #939598;
}
.ReactCrop__image{
  width:200px;
  height:200px
}
.image-upload>input {
  display: none;
}
.invoice-text{
 background: #e6e7e8;
border: none;
margin-bottom: 20px;
width: 280px;
height: 40px;
color: #939598;
border-radius: 3px;
}
.invoiceWrapper{
  font-family: 'Roboto';
}
.addressRow{
  /* width:85px;
  height:45px; */
  margin-top: 45px;
  /* margin-left: 20px; */
  margin-bottom: 8.46px;
}
.editorAndTable{
  /* margin-left: 25px; */
  margin-right: 20px;
  margin-top:140px;
}
.PreviewPageContainer{
  /* top: 0px; */
left: 200px;
/* margin-left: 100px; */
width: 800px;
height: 1132px;
opacity: 1;
align-content: center;
display: flex;
margin: 0 auto;
/* position: relative; */
}
.address{
  /* height:45px;
  width:85px; */
  /* height: 165px; */
  height: 165px;
    width: 390px;
/* width: 85px; */
  /* margin-right: 20px; */
  /* margin-top: 172px; */
  margin-top: 20px;
margin-left: 0px;
}
.senderAdress{
  /* top: 172px;
left: 101px; */
width: 361px;
height: 20px;
text-align: left;
font: normal normal normal 14px/29px Roboto;
letter-spacing: 0px;
color: #000000;
opacity: 1;
/* position: relative; */
}
.recipientAddress{
  /* top: 243px;
left: 101px; */
width: 361px;
height: 20px;
text-align: left;
font: normal normal normal 14px/29px Roboto;
letter-spacing: 0px;
color: #000000;
opacity: 1;
/* position: relative; */

}
.billingInfo{
  /* margin-top: 250px; */
 /*  margin-bottom: 10px;
  */
/* margin-left: 370px; */
/* position: relative; */
margin-bottom: 10px;
margin-top: 10px;
/* margin-left: 370px; */
position: absolute;
right: 10px;
/* width: 279px; */
height: 132px;
background: #FFFFFF 0% 0% no-repeat padding-box;
opacity: 1;

/* outline:2px solid black  */

}
.billingHeader{
  top: 198px;
left: 488px;
/* width: 361px; */
height: 20px;
text-align: left;
font: normal normal normal 14px/29px Roboto;
letter-spacing: 0px;
color: #000000;
opacity: 1;
}
.bill-box{
  top: 0px;
  left: 125px;
  /* width: 60px; */
  height: 19px;
  text-align: right;
  font: normal normal  18px/29px Roboto;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
}
.invoice-text-preview{
  text-align: right;
  font: normal normal normal 14px/29px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.contact-boxes{
  /* top: 1042px; */
margin-left: -950px;
width: 570px;
/* height: 61px; */
background: #FFFFFF 0% 0% no-repeat padding-box;
opacity: 1;

/* position: absolute; */
/* outline: 2px solid black; */
margin-top: 2900px;
}
.each-add1{
  top: 1046px;
left: 104px;
width: 127px;
height: 55px;
text-align: left;
font: normal normal normal 10px/13px Roboto;
letter-spacing: 0px;
color: #707070;
opacity: 1;
}
.closingText{
  margin-top: 50px;
  margin-left: 5px;
  width: 574px;
  height: 240px;
  /* position: absolute */
}
.each-add2{
  top: 1046px;
left: 248px;
width: 156px;
height: 64px;
text-align: left;
font: normal normal normal 10px/13px Roboto;
letter-spacing: 0px;
color: #707070;
opacity: 1;
}
.each-add3{
  top: 1046px;
left: 416px;
width: 171px;
height: 108px;
text-align: left;
font: normal normal normal 10px/13px Roboto;
letter-spacing: 0px;
color: #707070;
opacity: 1;
}
.each-add4{
  top: 1046px;
left: 587px;
width: 262px;
height: 108px;
text-align: left;
font: normal normal normal 10px/13px Roboto;
letter-spacing: 0px;
color: #707070;
opacity: 1;
}

.invoice-text-style{
  text-align: right;
font:  normal normal 25px/29px Crete Round;
letter-spacing: 0px;
color: #000000;
opacity: 1;
}
.invoice-text2{
  text-align: left;
font:  normal normal 20px/29px Crete Round;
letter-spacing: 0px;
color: #00000066;
opacity: 1;
}
.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: #666;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.drop_border{
  border:4px dotted green;
}

.btn-highlight{
  background-color: #40b640 !important;
}

/* .wrapper-unsplash {
  margin-top: 30px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
} */
/* .wrapper img:first-child,
.wrapper img:nth-child(2) {
  grid-column: span 2;
} */

/* .wrapper-unsplash img {
  border: 5px solid grey;
  object-fit: cover;
  width: 100%;
  height: auto; 
  grid-column: span 1;
} */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  /* margin-top: 30px;
  margin-bottom: 30px; */
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  overflow-x: hidden;
}

.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
  position: relative;
}

/* .checkbox{
  width: 1px;
  height: 1px;
  position: absolute;
  opacity: 0;
} */

/* .checkbox{
  width: 22px;
  height: 22px;
  position: absolute;
  top: 6px;
  right: 6px; 
  background: #fff;
  border-radius: 2px;
  border: 1px solid #8eb0d1;
} */
.checkbox{
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;  
  border-radius: 2px;
  border: 1px solid #8eb0d1;
}

/* .unsplash-item input +span 
  display: block;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #8eb0d1; 
  position: absolute;
  top: 6px;
  right: 6px; 
} */

/* .my-masonry-grid_column > div input+span 
  display: block;
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #8eb0d1; 
  position: absolute;
  top: 6px;
  right: 6px; 
} */

/* .checkbox:checked{
  content: "";
  display: block;
  width: 15px;
  height: 6px;
  border-left: 2px solid #939598;
  border-bottom: 2px solid #939598;
  transform: rotate(-45deg);
  position: absolute;
  left: 3px;
  top: 5px;
} */
.checkbox:checked{
  border-bottom: 2px solid #939598;
}

/* .unsplash-item input:checked + span:after {
  content: "";
  display: block;
  width: 15px;
  height: 6px;
  border-left: 2px solid #939598;
  border-bottom: 2px solid #939598;
  transform: rotate(-45deg);
  position: absolute;
  left: 3px;
  top: 5px;
} */
/* .my-masonry-grid_column > div input:checked + span:after {
  content: "";
  display: block;
  width: 15px;
  height: 6px;
  border-left: 2px solid #939598;
  border-bottom: 2px solid #939598;
  transform: rotate(-45deg);
  position: absolute;
  left: 3px;
  top: 5px;
} */

.wrapper-unsplash{
  position: relative;
  height: 535px;
  margin-bottom: 25px;
  overflow-y: auto;
  margin-top:25px;
}

.unsplash-library-header .search-input {
  line-height: 52px;
  background: url(./assets/magnifying-glass.svg) #d1d3d4 no-repeat right 17px
    top 17px;
  color: #939598;
  width: 400px;
  border: 0;
  border-radius: 3px;
  padding: 0 60px 0 12px;
}

.unsplash-sumbit{
   /* background: url(./assets/magnifying-glass.svg) #d1d3d4 no-repeat right 10px 
    top 6px; */
    /* border:1px solid grey; */
    /* background: #d1d3d4; */
    border: none;
    padding: 16px;
    border-radius: 3px;
    background: #00000000;
    background-repeat: no-repeat;
    width: 60px;
    height: 30px;
    background-size: auto;
    margin-left: -60px;  
}

.image-selector-modal .selector-container .unsplash-footer .footer-btn {
  line-height: 49px;
  width: 210px;
  background: #939598;
  color: #e6e7e8;
  font-size: 16px;
  border: 0;
  border-radius: 3px;
}

.image-selector-modal .selector-container .unsplash-footer .footer-btn:hover {
  background: #505050;
}

.image-selector-modal
  .selector-container
  .unsplash-footer
  .footer-btn:first-child {
  margin-right: 15px;
  border-radius: 3px;
}
th, td {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.table-row:hover {
  border: 0.1px solid #70707060;
  background-color: #fcfcfc !important;
  border-radius: 3px;
  box-shadow: 0 0 15px #dadada; 
}

.topIcon::-webkit-scrollbar { 
  display: none; 
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* scrollbar width */
.scrollbar::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
    background: #C9D6FF;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 10px;
}

/* scrollbar Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: #585858;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #cccccc, #cccccc);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #cccccc, #cccccc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 10px;
}

/* scrollbar Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #707070;
}
